import React from 'react'
import { Link } from 'react-router-dom'
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from '@material-ui/core/styles'
import { ReactComponent as Info } from '../common/images/icons/info.svg'
import { useTranslation } from 'react-i18next'

const styles = (theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      // borderBottom: '1px solid rgba(255,255,255,0.15)',
      backgroundColor: '#fff',
      boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 3px 0px'
    },
    headerTitle: {
      fontSize: 32,
      color: '#000',
      fontWeight: 300,
      marginLeft: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
        lineHeight: '24px',
        marginLeft: '12px'
      }
    },
    headerTitleSpan: {
      fontWeight: 700
    },
    infoLink: {
      color: '#000',
      marginRight: theme.spacing(4),
      textDecoration: 'none',
      textTransform: 'uppercase',
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        textDecoration: 'underline'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
        marginRight: theme.spacing(2)
      }
    },
    infoLinkIcon: {
      fill: '#000',
      paddingRight: theme.spacing(1)
    },
    logoLink: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none'
    },
    logoWrapper: {
      padding: theme.spacing(4),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2)
      }
    },
    logoImg: {
      height: '28px',
      [theme.breakpoints.down('sm')]: {
        height: '20px'
      }
    }
  })

type Props = WithStyles<typeof styles> & {
  logoFill?: string
}

const Header: React.FC<Props> = ({ classes, logoFill = '#000000' }) => {
  const { t } = useTranslation()

  return (
    <header>
      <div className={classes.header}>
        <Link to="/" className={classes.logoLink}>
          <div className={classes.logoWrapper}>
            <svg
              className={classes.logoImg}
              id="Calque_1"
              data-name="Calque 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 708.01 117.12"
            >
              <path fill={logoFill} d="M484.28 2.05h31.86V117.1h-31.86z" />
              <path
                fill={logoFill}
                d="M424 248.55c-19.86 9.7-29.94 26.89-29.94 51.11 0 23 9.1 39.34 27 48.69 14 7.28 28.82 7.85 33 7.85h71.39v-23.14H459.9c-10.87 0-31.43-7.2-31.43-34.43 0-34 31.06-34.4 31.37-34.4h65.73v-23.15h-70.46a78.21 78.21 0 00-31.11 7.47zM186 248.93c-14-7.29-28.82-7.85-33-7.85H66.63v115.11h85.44a78.25 78.25 0 0031.06-7.48c19.87-9.69 29.95-26.89 29.95-51.11-.02-22.94-9.08-39.32-27.08-48.67zm-38.7 84.12H98.47v-68.84h48.79c10.87 0 31.43 7.2 31.43 34.43 0 34.02-31.06 34.41-31.37 34.41zM346.23 266.71c-3.18-6-8.74-15.41-14.57-19.82-1-.72-8.2-6.22-23.12-7.59-1.71-.16-3.45-.22-5.2-.22h-.84c-1.75 0-3.49.06-5.2.22-14.92 1.37-22.1 6.87-23.12 7.59-5.83 4.41-11.39 13.79-14.57 19.82-5.42 11.06-10.36 21.18-15.31 31.57-7.51 15.77-17.28 36.46-26.78 57.91h35.35c3.51-7.28 11-23.12 11-23.12H342s7.48 15.84 11 23.12h35.34c-9.5-21.45-19.27-42.14-26.78-57.91-4.97-10.39-9.91-20.51-15.33-31.57zM274 311.22s10.81-23.75 15-33c0 0 3.27-7.4 4.29-8.67 1.39-1.71 3.58-4.53 9.66-4.53s8.28 2.82 9.66 4.53c1 1.27 4.29 8.67 4.29 8.67 4.16 9.28 15 33 15 33zM747.83 298.28c-4.94-10.39-9.88-20.51-15.3-31.57-3.18-6-8.74-15.41-14.57-19.82-1-.72-8.21-6.22-23.12-7.59a56.07 56.07 0 00-5.2-.22h-.85c-1.74 0-3.48.06-5.19.22-14.92 1.37-22.1 6.87-23.12 7.59-5.83 4.41-11.39 13.79-14.57 19.82-5.42 11.06-10.36 21.18-15.31 31.57-7.51 15.77-17.28 36.46-26.78 57.91h35.34c3.52-7.28 11-23.12 11-23.12h78.12s7.48 15.84 11 23.12h35.35c-9.51-21.45-19.28-42.14-26.8-57.91zm-87.53 12.94s10.81-23.75 15-33c0 0 3.26-7.4 4.29-8.67 1.38-1.71 3.58-4.53 9.66-4.53s8.27 2.82 9.66 4.53c1 1.27 4.29 8.67 4.29 8.67 4.16 9.28 15 33 15 33z"
                transform="translate(-66.61 -239.08)"
              />
            </svg>
          </div>
        </Link>
        <a
          href={t('hero.aboutDaciaCareLink')}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.infoLink}
        >
          <span className={classes.infoLinkIcon}>
            <Info />
          </span>
          {t('hero.aboutDaciaCare')}
        </a>
      </div>
    </header>
  )
}
export default withStyles(styles)(Header)
