import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import i18 from './common/i18n'
import './common/fonts.css'
import './index.css'
import './common/normalize.css'
import { unregister } from './serviceWorker'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import theme from './common/theme'
import Home from './components/home'
import { Lang } from './common/getLang'
import YourAgreement from './components/yourAgreement'

ReactDOM.render(
  <Router>
    <I18nextProvider i18n={i18}>
      <MuiThemeProvider theme={theme}>
        <Switch>
          <Route exact path="/" component={() => <Home lang={Lang} />} />
          <Route
            path="/#/your-agreement"
            component={props => (
              <YourAgreement lang={Lang} location={props.location} />
            )}
          />
        </Switch>
      </MuiThemeProvider>
    </I18nextProvider>
  </Router>,
  document.getElementById('root')
)

unregister()
