
export type LangType = {
  language: string
  locale: string
}  

function getQueryParam(name: string) {
  var q = window.location.search.match(new RegExp('[?&]' + name + '=([^&#]*)'))
  return q && q[1]
}

const countryCode = window.location.host.replace(/^.*\./, '')
let lang = {
  language: 'da',
  locale: 'dk'
} as LangType

const query = getQueryParam('lang')

if (query === 'sv' || (countryCode && countryCode === 'se')) {
  lang = {
    language: 'sv',
    locale: 'se'
  } as LangType
}

export const Lang = lang
