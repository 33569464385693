import React, { useState } from 'react'
import i18next from 'i18next'
import {
  withStyles,
  Typography,
  Collapse,
  Button,
  Theme,
  createStyles
} from '@material-ui/core'
import WidthLimiter from './widthLimiter'
import Cookies from 'universal-cookie'
import { WithStyles } from '@material-ui/styles'
import { Lang } from '../common/getLang'
import TagManager from 'react-gtm-module'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      textAlign: 'center',
      backgroundColor: '#fff',
      width: '100%',
      bottom: 0,
      borderBottom: '1px solid #dcdcdc'
    },
    text: {
      fontSize: '14px'
    },
    link: {
      color: theme.palette.primary.main
    },
    button: {
      marginLeft: theme.spacing(2),
      borderRadius: '100px',
      padding: theme.spacing(3),
      paddingTop: '6px',
      paddingBottom: '6px',
      border: '1px solid #122AFF',
      textTransform: 'none',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '24px',
      outline: 'none',
      boxShadow: 'none',
      '&:hover': {
        cursor: 'pointer',
        boxShadow: 'none'
      }
    },
    closeButton: {
      position: 'absolute',
      top: theme.spacing(2),
      right: theme.spacing(0),
      width: theme.spacing(1),
      border: 'none',
      background: 'none',
      '&::before': {
        top: 0,
        content: '""',
        position: 'absolute',
        height: '16px',
        width: '2px',
        transform: 'rotate(45deg)',
        backgroundColor: theme.palette.primary.main
      },
      '&::after': {
        content: '""',
        top: 0,
        position: 'absolute',
        height: '16px',
        width: '2px',
        transform: 'rotate(-45deg)',
        backgroundColor: theme.palette.primary.main
      },
      '&:hover': {
        background: 'none'
      },
      '& span': {
        opacity: 0
      }
    }
  })

type Props = WithStyles<typeof styles>

const initTagmanager = () => {
  const cookies = new Cookies()
  let cookiesAllowed = cookies.get('cookiesAllowed')

  if (cookiesAllowed && !window.dataLayer) {
    console.log('allow')
    // Set correct GTM container and google account for current locale
    const GTMContainer = Lang.locale === 'dk' ? 'GTM-KT2S6X' : 'GTM-NTR3P7'

    const googleAccount =
      Lang.locale === 'dk' ? 'UA-39005820-1' : 'UA-4728947-5'

    const tagManagerArgs = {
      gtmId: GTMContainer,
      dataLayer: {
        brand: 'Dacia',
        googleAccount: googleAccount,
        pageName: 'homepage',
        businessType: 'after-sales',
        pageType: 'mini-site',
        languageCode: Lang.language.toUpperCase(),
        countryCode: Lang.locale.toUpperCase()
      }
    }

    TagManager.initialize(tagManagerArgs)
  }
}

const CookieBanner: React.FC<Props> = ({ classes }) => {
  const cookies = new Cookies()
  const [isCookiesAllowed, setIsCookiesAllowed] = useState(getCookie()) //useState(getCookie(cookies))
  function getCookie() {
    let cookie = cookies.get('cookiesAllowed')
    if (cookie) {
      cookie = true
      initTagmanager()
    } else {
      cookie = false
    }
    return cookie
  }
  function setCookie(isAllowed: boolean) {
    cookies.set('cookiesAllowed', isAllowed, { path: '/' })
    setIsCookiesAllowed(true)
    initTagmanager()
  }
  return (
    <Collapse in={!isCookiesAllowed}>
      <div className={classes.root}>
        <WidthLimiter>
          <Typography
            component="span"
            className={classes.text}
            dangerouslySetInnerHTML={{
              __html: i18next.t('base.cookieBannerText', {
                link: `<a class="${
                  classes.link
                }" target="_blank" href="${i18next.t(
                  'base.cookieBannerLinkUrl'
                )}">${i18next.t('base.cookieBannerLinkText')}</a>`
              })
            }}
          />
          <Button
            onClick={() => setCookie(true)}
            className={classes.button}
            variant="contained"
            color="primary"
          >
            {i18next.t('base.cookieBannerAccept')}
          </Button>
          <Button
            className={classes.closeButton}
            onClick={() => setCookie(false)}
          >
            Close
          </Button>
        </WidthLimiter>
      </div>
    </Collapse>
  )
}

export default withStyles(styles)(CookieBanner)
