import sv_SE from './sv_SE'
import da_DK from './da_DK'
import en_EN from './en_EN'

const translations = {
  sv_SE,
  da_DK,
  en_EN
}

export default translations
