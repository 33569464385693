const translations = {
  // Basic words and expression used throughout the site
  'base.currency': 'kr',
  'base.month': 'Månad',
  'base.monthlyPayment': 'Månadsbetalning',
  'base.yearlyPayment': 'Årlig betalning',
  'base.years': 'År',
  'base.from': 'Från',
  'base.back': 'Tillbaka',
  'base.next': 'Nästa',
  'base.total': 'Totalt',
  'base.lengthUnit': 'mil',
  'base.firstName': 'Förnamn',
  'base.surname': 'Efternamn',
  'base.streetAddress': 'Gatuadress',
  'base.city': 'Stad',
  'base.postalCode': 'Postnummer',
  'base.email': 'E-post',
  'base.phoneNumber': 'Telefon',
  'base.payment': 'Betalning',
  'base.eInvoice': 'E-fakturering',
  'base.regNr': 'Registreringsnummer',
  'base.version': 'Version',
  'base.VAT': 'Moms',
  'base.VIN': 'Chassinummer',
  'base.monthlyCostExVAT': 'Månadskostnad exk moms',
  'base.monthlyCostIncVAT': 'Månadskostnad inklusive moms',
  'base.personalIdentityNumber': 'Personnummer',
  'base.personalIdentityNumberFormat': 'ÅÅÅÅMMDD-XXXX',
  'base.period': 'period',
  'base.cookieBannerText': 'Vår webbplats använder cookies. {{link}}',
  'base.cookieBannerLinkText': 'Läs mer om cookies',
  'base.cookieBannerLinkUrl': 'https://www.dacia.se/cookies/',
  'base.cookieBannerAccept': 'Jag godkänner',
  'base.totalMileage': 'Total körsträcka',
  'base.signContract': 'Skriv under avtal',

  // Startpage
  // In .heading and .sectionHeadin the word(s) after the * is the blue part of headline
  'hero.heading': 'Skaffa ditt *Dacia Care* idag',
  'hero.subHeading':
    'Dacia Care ger dig full kontroll över alla dina service- och reparations kostnader. Skapa din affär idag och skriv under direkt med BankID.',
  'hero.aboutDaciaCare': 'Om Dacia Care',
  'hero.aboutDaciaCareLink':
    'https://www.dacia.se/dacia-verkstaden/dacia-care/',
  'hero.searchLabel': 'Börja med att ange ditt registreringsnummer',
  'hero.searchLabelTooltip':
    'Bilen måste vara högst ett år för att vara berättigad till Dacia Care',
  'hero.noRegistrationNumber': 'Inget registreringsnummer?',
  'hero.calculateAPriceSuggestion': 'Beräkna ett prisförslag',
  'hero.searchButtonLabel': 'Hitta din Dacia',
  'hero.searchPlaceholder': 'ABC123',
  'hero.stepLabel': 'Skaffa Dacia Care med 3 enkla steg',
  'hero.step1': 'Hitta din Dacia',
  'hero.step2': 'Anpassa ditt avtal',
  'hero.step3': 'Skriv under med BankID',
  'hero.error.notDacia': 'Angivet registreringsnummer tillhör inte en Dacia',
  'hero.error.contactDealership':
    'Din Dacia är äldre än 8 månader, kontakta din <a target="_blank" href="https://www.dacia.se/aterforsaljare/">lokala Dacia-återförsäljare</a> för att underteckna ett Dacia Care-serviceavtal',
  'hero.error.tooOld':
    'Din Dacia är 12 månader eller äldre, du kan inte längre teckna ett Dacia Care serviceavtal för din Dacia,',
  'hero.error.unexpected':
    'Något oväntat gick fel, testa att ladda om sidan eller försök igen lite senare',
  'hero.error.contractExists':
    'Det finns redan ett service- och underhållskontrakt för denna bil.',
  'hero.error.noPrice':
    'Något oväntat har gått fel i sökningen. Gå tillbaka och försök igen, om felet kvarstår vänligen kontakta <a href="https://www.dacia.se/om-dacia/kontakta-oss/" target="_blank">kundtjänst</a>',
  'hero.disclaimer':
    'Dacia Care är ett nationellt service- och reparationsavtal som omfattar 4 komponenter för ett enkelt bilägande. Planlagd service, slitagedelar, förlängd nybilsgaranti och Dacia Assistance.',
  'usps.sectionHeading': 'Fördelar med *Dacia Care',
  'usps.focusOnYourDriving': 'Fokusera på din körning',
  'usps.focusOnYourDrivingText':
    'Med förebyggande underhåll på bilen kör du säkert, problemfritt och framförallt undviker du oväntade utgifter.',
  'usps.controlYourCosts': 'Få kontroll på dina kostnader',
  'usps.controlYourCostsText':
    'Med Dacia Care får du full kontroll över dina service- och reparationskostnader.',
  'usps.serviceWhereYouAre': 'Gör din service varsomhelst i landet',
  'usps.serviceWhereYouAreText':
    'Det är våra Dacia verkstäder som känner din bil bäst.',
  'usps.seeWhichOneIsClosestToYou': 'Se vilken som är närmast dig',
  'usps.seeWhichOneIsClosestToYouLink': 'https://www.dacia.se/aterforsaljare/',
  'usps.learnMoreAboutDaciaCare': 'Läs mer om Dacia Care',
  'indicatePrice.heading': 'Beräkna *ditt pris',
  'indicatePrice.subHeading': 'Börja med att välja modell',
  'adjustAgreement.adjustYourAgreement': 'Anpassa *ditt avtal',
  'adjustAgreement.selectVersion': 'Välj version',
  'adjustAgreement.numberYears': 'Antal år',
  'adjustAgreement.numberYearsTooltip':
    'Välj antal år som du vill ha Dacia Care. Du kan närsomhelst förlänga ditt avtal.',
  'adjustAgreement.yearlyMileage': 'Årlig körsträcka',
  'adjustAgreement.yearlyMileageTooltip':
    'Välj årlig förväntad körsträcka. Du kan närsomhelst utöka din körsträcka.',
  'adjustAgreement.payment': 'Betalning',
  'adjustAgreement.paymentTooltip':
    'Fill in the personal identification number of the person who is registered owner of the car in the Road Traffic Register. Your personal information is handled in accordance with the Personal Data Act.',
  'adjustAgreement.monthly': 'Månatlig',
  'adjustAgreement.monthlyDescription': 'Fri från ränta och andra kostnader',
  'adjustAgreement.yearly': 'Årlig',
  'adjustAgreement.yearlyDescription': 'Du betalar för hela perioden',
  'adjustAgreement.interestedInGettingDaciaCare':
    'Intresserad av att få Dacia Care?',
  'adjustAgreement.contactUs': 'Kontakta oss',

  'footer.privacyText': 'Kontakta oss',
  'footer.privacyLink': 'https://www.dacia.se/om-dacia/kontakta-oss/',
  'footer.cookieText': 'Cookies',
  'footer.cookieLink': 'https://www.dacia.se/cookies/',
  'footer.copyright':
    '© Renault Finance Nordic bankfilial till RCI Banque SA Frankrike',
  // Your agreement page
  'yourAgreement.step1': 'Hitta din Dacia',
  'yourAgreement.step2': 'Anpassa ditt avtal',
  'yourAgreement.step3': 'Skriv under ditt avtal',
  // Step 1
  'yourAgreement.step1.heading': 'Hitta din *Dacia',
  'yourAgreement.step1.inTraffic': 'I trafik',
  'yourAgreement.step1.wrongCar': 'Fel bil? Sök igen',
  // Step 2 not needed since same as adjust agreement on startpage
  // Step 3
  'yourAgreement.step3.heading': 'Skriv under *ditt avtal',
  'yourAgreement.step3.subHeading': 'Din information',
  'yourAgreement.step3.personalIdentityNumberTooltip':
    'Fyll i ägarens personnummer. Din personliga information hanteras i enlighet med gällande dataskyddslagstiftning.',
  'yourAgreement.step3.eInvoiceText':
    'Fakturan för din första månad skickas via e-post till {{email}}. Därifrån kan du välja betalningsmetod och justera ditt serviceavtal.',
  'yourAgreement.step3.eInvoiceText2ndPart':
    'Kom ihåg att du alltid har 30 dagar ångerrätt.',
  'yourAgreement.step3.acceptNewsLetter':
    'Jag vill gärna få information om Dacia från Renault Finance Nordic AB på e-post - inklusive nyhetsbrev och annan marknadsföring.',
  'yourAgreement.step3.marketingConsent':
    'Läs mer om avtalets <a href="/terms-and-conditions-se.pdf" target="_blank">villkor</a>.',
  'yourAgreement.step3.summary': 'Sammanfattning',
  'yourAgreement.step3.change': 'Byt',
  'yourAgreement.step3.total': 'Total',
  'yourAgreement.step3.priceDescription':
    'Priset är inklusive moms, beloppet avser per månad är utan räntor och andra omkostnader.',
  'yourAgreement.step3.signedHeading':
    'Du har nu skrivit under ditt *Dacia Care* avtal!',
  'yourAgreement.step3.signedSubHeading': 'Vad händer nu?',
  'yourAgreement.step3.signedSubHeadingText': 'Vad händer nu?',
  'yourAgreement.step3.signedText':
    'Fakturan för din första månad skickas via e-post till {{email}}. Därifrån kan du välja betalningsmetod och justera ditt serviceavtal.</br>Boka din årliga service hos någon av våra <a href="">150 Dacia-certifierade verkstäder</a> över hela landet.',
  'yourAgreement.step3.showAgreement': 'Visa mitt *Dacia Care* avtal',
  'yourAgreement.step3.termsAndConditions': 'Villkor',
  'yourAgreement.step3.createingContract': 'Skapar kontrakt',
  'yourAgreement.step3.processingDetails':
    'Bearbetar uppgifter, detta kan ta en liten stund...',
  'yourAgreement.step3.unexpectedIssue':
    'Något oväntat har gått fel i kontraktsgenereringen. Gå tillbaka och försök igen, om felet kvarstår vänligen kontakta <a href="https://www.dacia.se/om-dacia/kontakta-oss/" target="_blank">kundtjänst</a>',
  'yourAgreement.step3.generatedContract':
    'Ditt kontrakt har skapats upp och är klart att signeras.',
  'serviceAgreement.personalInformation': 'Personlig information',
  'serviceAgreement.vehicleInfo': 'Fordonsinformation',
  'serviceAgreement.warrantyStartDate': 'Garantistartdatum',
  'serviceAgreement.periodNote':
    'Avtalet slutar när antingen perioden löpt ut eller den totala körsträckan har uppnåtts.',
  'errors.mandatory': 'Fältet är obligatoriskt',
  'errors.email': 'E-postadressen verkar inte vara giltigt',
  'errors.personalIdentityNumber':
    'Personnumret verkar inte vara giltigt. Det behöver vara enligt följande format ÅÅÅÅMMDDXXXX',
  'integrity.part1':
    'Dina personuppgifter behandlas av Renault Nordic AB och medlemmarna i det auktoriserade återförsäljarnätverket som självständigt personuppgiftsansvariga. Dina personuppgifter kommer att behandlas av en säljare hos den återförsäljare du har valt för att kontakta dig angående provkörningen eller erbjudandet du har bett om. Du har rätt att begära att få tillgång till dina personuppgifter. Du kan även ha rätt att begära rättelse av dina personuppgifter, eller att få dem raderade. Du kan invända mot behandling av dina personuppgifter, utan att ange dina skäl för detta, när det gäller invändning mot att motta direktmarknadsföring, och baserat på skäl som relaterar till din specifika situation i andra fall än invändningar mot direktmarknadsföring. Du kan även begära begränsning av behandling av dina personuppgifter. Du kan be om att få ut en kopia av dina personuppgifter i ett strukturerat standardformat.',
  'integrity.part2':
    'För att utöva någon av dessa rättigheter kan du, när din identitet har säkerställts, kontakta oss via e-post på privacy.nordic@renault.com, via post på Box 1028, 164 21, Kista eller genom att fylla i formuläret <a href="https://www.dacia.se/om-dacia/kontakta-oss/" target="_blank">här</a>.',
  'integrity.part3':
    'För mer information, vänligen se vår <a href="https://www.dacia.se/integritetspolicy/">integritetspolicy</a>'
}

export default translations
