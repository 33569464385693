import React from 'react'
import { Redirect } from 'react-router'
import i18next from 'i18next'
import axios from 'axios'
import {
  withStyles,
  Stepper,
  StepLabel,
  Step,
  StepConnector,
  Button,
  Fade
} from '@material-ui/core'
import Header from '../../sharedComponents/header'
import BottomBar from './bottomBar'
import WidthLimiter from '../../sharedComponents/widthLimiter'
import CookieBanner from '../../sharedComponents/cookieBanner'
import Footer from '../../sharedComponents/footer'
import FindYourCar from './findYourCar'
import AdjustYourAgreement from './adjustYourAgreement'
import YourDetails from './yourDetails'
import SignYourAgreement from './signYourAgreement'
import { ReactComponent as BackIcon } from '../../common/images/icons/chevron-left.svg'
import { Lang, LangType } from '../../common/getLang'
import { convertMileage } from '../../common/convertMileage'
import styles from './styles'
import { WithStyles } from '@material-ui/styles'
import PersonalInfo from 'types/personalInfo'
import CarInfo from 'types/carInfo'
import ProductJson from 'types/productJson'

type OwnProps = {
  lang: LangType
  personalInfo: PersonalInfo
  carInfo: CarInfo
  configuration: ProductJson['configuration']
  model: string
  handleSigningError: () => void
  validateForm: boolean
  price: number
  description: string
  modelType: string
  color: string
  handleSubmit: (value: boolean) => void
  handleAgreementState: (value: object) => void
  location: Location
}

type State = {
  configuration: ProductJson['configuration']
  personalInfo: PersonalInfo
  activeStep: number
  redirectToStart: boolean
  validateForm: boolean
  submit: boolean
  price: string
  licensePlate: string
  colorCode: string
  brand: string
  description: string
  model: string
  modelCode: string
  version: string
  firstRegistrationDate: string
  vin: string
  showBottomBar: boolean
  bearerToken: string
  priceId: string
  commercialDescription: string
  errorMessage: string
  error: boolean
  disabled: boolean
}

type CarData = {
  modelCode: string
  versionCode: string
  vin: string
  description: string
  brand: string
  firstRegistrationDate: string
  colorCode: string
  commercialDescription: string
}

type Location = {
  state: {
    carData: CarData
    regnr: string
  }
}

type Props = OwnProps & WithStyles<typeof styles> & CarData & Location

class YourAgreement extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    const carData = this.props.location.state.carData
    const licensePlate = this.props.location.state.regnr
    const {
      modelCode,
      versionCode,
      vin,
      description,
      commercialDescription,
      brand,
      firstRegistrationDate,
      colorCode
    } = carData as CarData

    const model = description.split(' ')[0].toLowerCase()

    this.state = {
      activeStep: 0,
      configuration: props.configuration,
      redirectToStart: false,
      validateForm: false,
      submit: false,
      price: '',
      licensePlate: licensePlate,
      colorCode: colorCode,
      brand: brand,
      description: description,
      model: model,
      modelCode: modelCode,
      version: versionCode,
      firstRegistrationDate: firstRegistrationDate,
      vin: vin,
      showBottomBar: true,
      commercialDescription: commercialDescription,
      bearerToken: '',
      priceId: '',
      errorMessage: '',
      error: false,
      disabled: false,
      personalInfo: {
        socialSecurityNumber: '',
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        zipCode: '',
        email: '',
        phoneNumber: '',
        acceptNewsLetter: false
      }
    }

    this.handleAgreementState = this.handleAgreementState.bind(this)
    this.handleConfiguration = this.handleConfiguration.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this.handleSigningError = this.handleSigningError.bind(this)
  }
  componentDidMount() {
    axios.get(`../data-${this.props.lang.locale}.json`).then(res => {
      this.setState(
        {
          configuration: res.data.configuration
        },
        () => {
          this.handleConfiguration(this.state.configuration)
        }
      )
    })

    const headers = {
      'Content-Type': 'application/json'
    }

    axios
      .post(
        '/api/auth',
        {
          user: process.env.REACT_APP_USR,
          password: process.env.REACT_APP_PSW
        },
        {
          headers: headers
        }
      )
      .then(res => {
        this.setState({
          bearerToken: res.data.token
        } as any)
      })
      .catch(function(error) {})
  }
  getSteps = () => {
    return [
      i18next.t('yourAgreement.step1'),
      i18next.t('yourAgreement.step2'),
      i18next.t('yourAgreement.step3')
    ]
  }
  getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <div>
            {this.state.configuration && (
              <FindYourCar
                licensePlate={this.state.licensePlate}
                brand={this.state.brand}
                model={this.state.modelCode}
                description={this.state.description}
                version={this.state.version}
                inTraffic={this.state.firstRegistrationDate}
                color={this.state.colorCode}
                modelType={this.state.model}
                handleConfiguration={this.handleConfiguration.bind(this)}
                configuration={this.state.configuration}
                lang={Lang}
                commercialDescription={this.state.commercialDescription}
                errorMessage={this.state.errorMessage}
                error={this.state.error}
              />
            )}
          </div>
        )
      case 1:
        return (
          <AdjustYourAgreement
            configuration={this.state.configuration}
            handleConfiguration={this.handleConfiguration.bind(this)}
            lang={Lang}
          />
        )
      case 2:
        return (
          <YourDetails
            personalInfo={this.state.personalInfo}
            brand={this.state.brand}
            description={this.state.description}
            version={this.state.version}
            configuration={this.state.configuration}
            handleAgreementState={this.handleAgreementState.bind(this)}
            handleSubmit={this.handleSubmit.bind(this)}
            validateForm={this.state.validateForm}
            price={this.state.price}
            color={this.state.colorCode}
            modelType={this.state.model}
            lang={Lang}
          />
        )
      case 3:
        return (
          <SignYourAgreement
            licensePlate={this.state.licensePlate}
            description={this.state.description}
            personalInfo={this.state.personalInfo}
            configuration={this.state.configuration}
            vinCode={this.state.vin}
            inTraffic={this.state.firstRegistrationDate}
            priceId={this.state.priceId}
            bearerToken={this.state.bearerToken}
            handleSigningError={this.handleSigningError.bind(this)}
            lang={Lang}
          />
        )
      default:
        return ''
    }
  }
  handleNext = () => {
    this.getSteps()
    if (this.state.activeStep !== this.getSteps().length - 1) {
      this.setState({ activeStep: this.state.activeStep + 1 })

      // Scroll to top to prevent missing content
      window.scrollTo({
        top: 0
      })
    } else {
      if (this.state.activeStep === 2) {
        this.setState({ showBottomBar: false })
      }
      if (this.state.activeStep === 2)
        this.setState(
          {
            validateForm: true
          },
          () => {
            this.validateForm()
            this.setState({
              validateForm: false
            })
          }
        )
    }
  }
  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 })

    // Scroll to top to prevent missing content
    window.scrollTo({
      top: 0
    })
    if (this.state.activeStep <= 0) {
      this.setState({
        redirectToStart: true
      })
    }
  }

  handleSigningError = () => {
    this.setState({
      showBottomBar: true
    })
  }
  handleConfiguration = (configuration: ProductJson['configuration']) => {
    this.setState(
      {
        configuration: configuration
      },
      () => {
        axios
          .post('/api/price', {
            Language: Lang.language,
            ContractPeriod: this.state.configuration.currentYears * 12,
            Mileage: convertMileage(
              this.state.configuration.currentMileage,
              this.state.configuration.currentYears
            ),
            Model: this.state.description
          })
          .then(response => {
            this.handlePriceChange(
              parseInt(response.data.basePrice.priceIncVat) /
                (this.state.configuration.currentYears * 12),
              response.data.priceId
            )
          })
          .catch(error => {
            this.setState({
              errorMessage: i18next.t('hero.error.noPrice'),
              error: true,
              disabled: false
            })
            console.log(error)
          })
      }
    )
  }
  handlePriceChange = (price: number, priceId: string) => {
    this.setState({
      price: price,
      priceId: priceId
    } as any)
  }
  handleSubmit = (submit: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(
      {
        submit: submit
      } as any,
      () => {
        this.validateForm()
      }
    )
  }
  handleAgreementState = (agreementInput: string) => {
    this.setState({
      personalInfo: agreementInput
    } as any)
  }
  validateForm = () => {
    if (!this.state.submit) {
      this.setState({ validateForm: true }, () => {})
    } else {
      this.setState({
        activeStep: 3
      })
    }
  }
  render() {
    const { redirectToStart } = this.state

    if (redirectToStart) {
      return (
        <Redirect
          to={{
            pathname: '/'
          }}
        />
      )
    }
    return (
      <Fade in={true} timeout={{ enter: 1000, exit: 500 }}>
        <div className={this.props.classes.root}>
          <CookieBanner />
          <Header
            classes={{
              header: this.props.classes.header,
              infoLink: this.props.classes.infoLink,
              headerTitle: this.props.classes.headerTitle
            }}
            logoFill="#000000"
          />
          <WidthLimiter>
            <Stepper
              activeStep={this.state.activeStep}
              className={this.props.classes.stepper}
              connector={
                <StepConnector
                  classes={{
                    active: this.props.classes.connectorActive,
                    completed: this.props.classes.connectorCompleted,
                    disabled: this.props.classes.connectorDisabled,
                    line: this.props.classes.connectorLine
                  }}
                />
              }
              alternativeLabel
            >
              {this.getSteps().map((label: string) => (
                <Step key={label}>
                  <StepLabel
                    className={this.props.classes.step}
                    classes={{
                      label: this.props.classes.stepLabel
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {this.getStepContent(this.state.activeStep)}
          </WidthLimiter>
          {this.state.showBottomBar && (
            <BottomBar>
              <div className={this.props.classes.fixedBottomWrapper}>
                <Button
                  className={this.props.classes.backButton}
                  onClick={this.handleBack}
                >
                  <BackIcon className={this.props.classes.nextButtonIcon} />
                  {i18next.t('base.back')}
                </Button>
                {this.state.activeStep !== 3 && (
                  <div className={this.props.classes.bottomBarRightPart}>
                    {this.state.price && (
                      <div className={this.props.classes.summary}>
                        <span>
                          {this.state.activeStep > 0
                            ? i18next.t('base.total')
                            : i18next.t('base.from')}
                          <b className={this.props.classes.priceAmount}>
                            {this.state.price} {i18next.t('base.currency')}
                          </b>
                          /{i18next.t('base.month')}
                        </span>
                      </div>
                    )}
                    <Button
                      classes={{ disabled: this.props.classes.disabledButton }}
                      variant="contained"
                      color="primary"
                      onClick={this.handleNext}
                      disabled={this.state.disabled}
                    >
                      {this.state.activeStep === this.getSteps().length - 1
                        ? i18next.t('base.signContract')
                        : i18next.t('base.next')}
                    </Button>
                  </div>
                )}
              </div>
            </BottomBar>
          )}
          <Footer />
        </div>
      </Fade>
    )
  }
}

export default withStyles(styles)(YourAgreement)
