import { Lang } from './getLang'

function convertMileage(mileage: number, years: number) {
  if (Lang.language === 'sv') {
    // In sweden return total milage e.g. milage * years with some custom roundups according to matrice
    let totalMileage = mileage * years
    switch (totalMileage) {
      case 4500:
        totalMileage = 5000
        break
      case 7500:
        totalMileage = 8000
        break
      case 12500:
        totalMileage = 13000
        break
    }
    // Convert from swedish mil to km
    return totalMileage * 10
  } else {
    return mileage
  }
}

export { convertMileage }
