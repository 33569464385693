import React from 'react'
import i18next from 'i18next'
import {
  withStyles,
  Grid,
  Slide,
  Typography,
  Theme,
  createStyles,
  WithStyles
} from '@material-ui/core'
import { Lang, LangType } from '../../common/getLang'
import SectionHeading from '../../sharedComponents/sectionHeading'
import CustomTextField from '../../sharedComponents/customTextField'
import CustomCheckbox from '../../sharedComponents/customCheckbox'
import Img from 'react-image'
import PersonalInfo from 'types/personalInfo'
import ProductJson from 'types/productJson'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: '144px',
      [theme.breakpoints.down('sm')]: {
        paddingBottom: '12px'
      }
    },
    heading: {
      fontWeight: 300,
      marginBottom: theme.spacing(6),
      fontSize: 42,
      lineHeight: '48px',
      textAlign: 'center',
      display: 'block'
    },
    headingSpan: {
      fontWeight: 700,
      color: theme.palette.primary.main
    },
    subheading: {
      fontSize: 26,
      lineHeight: '34px'
    },
    productImage: {
      mixBlendMode: 'multiply',
      maxWidth: '100%'
    },
    summaryTitle: {
      borderBottom: '1px solid #DCDCDC',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '26px',
      paddingBottom: theme.spacing(2)
    },
    listItem: {
      borderBottom: '1px solid #DCDCDC',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'center',
      margin: 0,
      padding: theme.spacing(3),
      paddingLeft: 0,
      paddingRight: 0
    },
    price: {
      fontWeight: 500,
      fontSize: '26px',
      lineHeight: '34px'
    },
    summaryColumn: {
      backgroundColor: '#FBFBFB',
      [theme.breakpoints.down('sm')]: {
        order: -1
      }
    },
    priceDescription: {
      marginTop: theme.spacing(3),
      display: 'block',
      fontSize: '14px'
    },
    paymentInfo: {
      border: '1px solid #DCDCDC',
      backgroundColor: '#fff',
      padding: theme.spacing(3),
      marginBottom: theme.spacing(3)
    },
    email: {
      fontWeight: 700
    },
    paymentInfoHeading: {
      fontSize: '16px',
      lineHeight: '24px',
      marginBottom: theme.spacing(1)
    },
    paymentInfoText: {
      fontSize: '14px',
      lineHeight: '22px'
    },
    disclaimer: {
      display: 'block',
      borderTop: '1px solid #DCDCDC',
      paddingTop: theme.spacing(1),
      fontSize: '14px',
      lineHeight: 1.3
    },
    label: {
      display: 'block',
      fontSize: '16px',
      lineHeight: '24px',
      marginBottom: theme.spacing(1)
    },
    sectionHeading: {
      fontSize: '52px'
    },
    agreementParagraph: {
      fontSize: '13px',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(5)
    },
    marketingParagraph: {
      fontSize: '13px',
      marginBottom: theme.spacing(2)
    }
  })

type OwnProps = {
  lang: LangType
  personalInfo: PersonalInfo
  configuration: ProductJson['configuration']
  handleSigningError?: () => void
  validateForm: boolean
  price: string
  description: string
  modelType: string
  color: string
  handleSubmit: (submit: React.ChangeEvent<HTMLInputElement> | any) => void
  handleAgreementState: (value: any) => void
  brand: string
  version: string
}

type State = {
  handleValidation: boolean
  socialSecurityNumberError: string
  firstNameError: string
  lastNameError: string
  addressError: string
  cityError: string
  zipCodeError: string
  emailError: string
  phoneNumberError: string
  validateForm: boolean
  [key: string]: any
} & PersonalInfo

type Props = OwnProps & WithStyles<typeof styles>

type FakeEvent = {
  target: {
    name: string
    value: string
  }
}

class YourDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      ...props.personalInfo,
      handleValidation: true,
      socialSecurityNumberError: '',
      firstNameError: '',
      lastNameError: '',
      addressError: '',
      cityError: '',
      zipCodeError: '',
      emailError: '',
      phoneNumberError: '',
      validateForm: true
    }
    this.handleTextFieldChange = this.handleTextFieldChange.bind(this)
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.validateField = this.validateField.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }
  componentDidMount() {
    this.setState(this.props.personalInfo)

    if (window.dataLayer) {
      const googleAccount =
        this.props.lang.locale === 'dk' ? 'UA-39005820-1' : 'UA-4728947-5'

      const tagManagerArgs = {
        brand: 'Dacia',
        googleAccount: googleAccount,
        languageCode: this.props.lang.language.toUpperCase(),
        countryCode: this.props.lang.locale.toUpperCase(),
        pageName: 'your-details',
        formName: 'service-contract-online',
        formType: 'service_contract_request',
        formCategory: 'lead_aftersales',
        formStepName: 'enter-details',
        formStepNumber: 'step-2',
        businessType: 'after-sales',
        pageType: 'mini-site',
        event: 'formSteps',
        vehicleCateogry: 'personal-cars'
      }
      window.dataLayer.push(tagManagerArgs)
    }
  }
  componentDidUpdate() {
    if (this.props.validateForm) {
      let errorArr: string[][] = []
      Object.keys(this.state).forEach(e =>
        errorArr.push(
          this.validateField({ target: { name: e, value: this.state[e] } })
        )
      )
      let stateCopy = { ...this.state }
      let errorsInForm = false
      for (let i = 0; i < errorArr.length; i++) {
        //should always be empty string and never undefined, needs research
        if (typeof errorArr[i] !== 'undefined') {
          let name = errorArr[i][0]
          let value = errorArr[i][1]
          stateCopy[name] = value
          if (errorArr[i][1].length > 1) {
            errorsInForm = true
          }
        }
      }
      if (!errorsInForm) {
        this.props.handleSubmit(true)
      }
      this.setState(stateCopy, () => {
        this.setState({ validateForm: false })
      })
    }
  }
  handleTextFieldChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState(
      {
        [e.target.id]: e.target.value
      } as any,
      () => {
        this.props.handleAgreementState(this.state)
      }
    )
  }
  handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    let id = e.target.id
    let val = e.target.checked
    this.setState(
      {
        [id]: val
      } as any,
      () => {
        this.props.handleAgreementState(this.state)
      }
    )
  }
  handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
    const errorMessageArray = this.validateField(e)
    const key = errorMessageArray[0]
    const val = errorMessageArray[1]
    const input = e.target
    const inputValue = input.value
    this.setState(
      {
        [key]: val
      } as any,
      () => {
        if (inputValue.length && val.length === 0) {
          ;(input.closest('div') as HTMLElement).classList.add('is-valid')
        } else {
          ;(input.closest('div') as HTMLElement).classList.remove('is-valid')
        }
      }
    )
  }

  validateField(e: React.ChangeEvent<HTMLInputElement> | FakeEvent) {
    const { name, value } = e.target
    const errorName = name + 'Error'
    let errorText = ''
    switch (name) {
      case 'socialSecurityNumber':
        let regex = /^[0-9]{2}[0,1][0-9][0-9]{2}-?[0-9]{4}$/
        if (Lang.language === 'sv') {
          regex = /^(19|20)?(\d{6}(-|\s)\d{4}|(?!19|20)\d{10})$/
        }
        if (value.length < 1) {
          errorText = i18next.t('errors.mandatory')
        } else if (!value.match(regex)) {
          errorText = i18next.t('errors.personalIdentityNumber')
        }
        break
      case 'firstName':
        if (value.length < 1) {
          errorText = i18next.t('errors.mandatory')
        }
        break
      case 'lastName':
        if (value.length < 1) {
          errorText = i18next.t('errors.mandatory')
        }
        break
      case 'address':
        if (value.length < 1) {
          errorText = i18next.t('errors.mandatory')
        }
        break
      case 'city':
        if (value.length < 1) {
          errorText = i18next.t('errors.mandatory')
        }
        break
      case 'zipCode':
        if (value.length < 1) {
          errorText = i18next.t('errors.mandatory')
        }
        break
      case 'email':
        if (value.length < 1) {
          errorText = i18next.t('errors.mandatory')
        } else if (
          !value.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ) {
          errorText = i18next.t('errors.email')
        }
        break
      case 'phoneNumber':
        if (value.length < 1) {
          errorText = i18next.t('errors.mandatory')
        }
        break
      default:
        break
    }
    return [errorName, errorText]
  }
  render() {
    return (
      <Slide in={true} direction="left" timeout={{ enter: 350, exit: 350 }}>
        <form className={this.props.classes.root}>
          <SectionHeading
            title={i18next.t('yourAgreement.step3.heading')}
            classes={{
              sectionHeading: this.props.classes.sectionHeading
            }}
            headingLvl="h1"
          />
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12} md={7}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <CustomTextField
                    handleTextFieldChange={this.handleTextFieldChange.bind(
                      this
                    )}
                    handleBlur={this.handleBlur.bind(this)}
                    label={
                      i18next.t('base.personalIdentityNumber') +
                      ` (${i18next.t('base.personalIdentityNumberFormat')})`
                    }
                    name="socialSecurityNumber"
                    id="socialSecurityNumber"
                    value={this.state.socialSecurityNumber}
                    validationError={this.state.socialSecurityNumberError}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CustomTextField
                    handleTextFieldChange={this.handleTextFieldChange.bind(
                      this
                    )}
                    handleBlur={this.handleBlur.bind(this)}
                    label={i18next.t('base.firstName')}
                    name="firstName"
                    id="firstName"
                    autocomplete="given-name"
                    value={this.state.firstName}
                    validationError={this.state.firstNameError}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CustomTextField
                    handleTextFieldChange={this.handleTextFieldChange.bind(
                      this
                    )}
                    handleBlur={this.handleBlur.bind(this)}
                    label={i18next.t('base.surname')}
                    name="lastName"
                    id="lastName"
                    autocomplete="family-name"
                    value={this.state.lastName}
                    validationError={this.state.lastNameError}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CustomTextField
                    handleTextFieldChange={this.handleTextFieldChange.bind(
                      this
                    )}
                    handleBlur={this.handleBlur.bind(this)}
                    label={i18next.t('base.streetAddress')}
                    name="address"
                    id="address"
                    autocomplete="street-address"
                    value={this.state.address}
                    validationError={this.state.addressError}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CustomTextField
                    handleTextFieldChange={this.handleTextFieldChange.bind(
                      this
                    )}
                    handleBlur={this.handleBlur.bind(this)}
                    label={i18next.t('base.city')}
                    name="city"
                    id="city"
                    autocomplete="address-level2"
                    value={this.state.city}
                    validationError={this.state.cityError}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CustomTextField
                    handleTextFieldChange={this.handleTextFieldChange.bind(
                      this
                    )}
                    handleBlur={this.handleBlur.bind(this)}
                    label={i18next.t('base.postalCode')}
                    name="zipCode"
                    id="zipCode"
                    autocomplete="postal-code"
                    value={this.state.zipCode}
                    validationError={this.state.zipCodeError}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CustomTextField
                    handleTextFieldChange={this.handleTextFieldChange.bind(
                      this
                    )}
                    handleBlur={this.handleBlur.bind(this)}
                    label={i18next.t('base.email')}
                    name="email"
                    id="email"
                    autocomplete="email"
                    value={this.state.email}
                    validationError={this.state.emailError}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CustomTextField
                    handleTextFieldChange={this.handleTextFieldChange.bind(
                      this
                    )}
                    handleBlur={this.handleBlur.bind(this)}
                    label={i18next.t('base.phoneNumber')}
                    name="phoneNumber"
                    id="phoneNumber"
                    autocomplete="tel"
                    value={this.state.phoneNumber}
                    validationError={this.state.phoneNumberError}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <div>
                    <Typography
                      component="span"
                      className={this.props.classes.label}
                    >
                      {i18next.t('base.payment')}{' '}
                    </Typography>
                    <div className={this.props.classes.paymentInfo}>
                      <Typography
                        component="p"
                        className={this.props.classes.paymentInfoHeading}
                      >
                        {i18next.t('base.eInvoice')}
                      </Typography>
                      <Typography
                        component="p"
                        className={this.props.classes.paymentInfoText}
                        dangerouslySetInnerHTML={{
                          __html: i18next.t(
                            'yourAgreement.step3.eInvoiceText',
                            {
                              email: `<b class="${this.props.classes.email}">${this.state.email}</b>`
                            }
                          )
                        }}
                      />
                      <Typography
                        component="p"
                        className={this.props.classes.paymentInfoText}
                      >
                        {i18next.t('yourAgreement.step3.eInvoiceText2ndPart')}
                      </Typography>
                    </div>
                  </div>
                  <CustomCheckbox
                    label={i18next.t('yourAgreement.step3.acceptNewsLetter')}
                    name="acceptNewsLetter"
                    id="acceptNewsLetter"
                    handleChange={this.handleCheckboxChange.bind(this)}
                    defaultChecked={this.state.acceptNewsLetter}
                  />
                  <div>
                    <Typography
                      component="p"
                      className={this.props.classes.marketingParagraph}
                      dangerouslySetInnerHTML={{
                        __html: i18next.t('integrity.part1')
                      }}
                    />
                    <Typography
                      component="p"
                      className={this.props.classes.marketingParagraph}
                      dangerouslySetInnerHTML={{
                        __html: i18next.t('integrity.part2')
                      }}
                    />
                    <Typography
                      component="p"
                      className={this.props.classes.marketingParagraph}
                      dangerouslySetInnerHTML={{
                        __html: i18next.t('integrity.part3')
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              className={this.props.classes.summaryColumn}
            >
              <Img
                className={this.props.classes.productImage}
                src={[
                  `/static/images/vehicle-models/${this.props.modelType}-${this.props.color}.png`,
                  `/static/images/vehicle-models/${this.props.modelType}.png`
                ]}
                alt={`${this.props.modelType} ${this.props.color}`}
              />
              <div>
                <Typography
                  component="h2"
                  className={this.props.classes.summaryTitle}
                >
                  {i18next.t('yourAgreement.step3.summary')}
                </Typography>
                <div className={this.props.classes.listItem}>
                  <span>{this.props.description}</span>
                </div>
                <div className={this.props.classes.listItem}>
                  <span>
                    {this.props.configuration.currentYears}{' '}
                    {i18next.t('base.years')},{' '}
                    {this.props.configuration.currentMileage}{' '}
                    {i18next.t('base.lengthUnit')}
                  </span>
                </div>
                <div>
                  <dl className={this.props.classes.listItem}>
                    <dt>{i18next.t('base.total')}</dt>
                    <dd>
                      <b className={this.props.classes.price}>
                        {this.props.price} {i18next.t('base.currency')}
                      </b>
                      <span>/{i18next.t('base.month')}</span>
                    </dd>
                  </dl>
                </div>
                <span className={this.props.classes.priceDescription}>
                  {i18next.t('yourAgreement.step3.priceDescription')}
                  <span
                    className={this.props.classes.agreementParagraph}
                    dangerouslySetInnerHTML={{
                      __html: i18next.t('yourAgreement.step3.marketingConsent')
                    }}
                  />
                </span>
              </div>
            </Grid>
          </Grid>
        </form>
      </Slide>
    )
  }
}

export default withStyles(styles)(YourDetails)
