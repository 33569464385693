import React from 'react'
import { withStyles, WithStyles, Theme, createStyles } from '@material-ui/core'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      '&::after': {
        content: '"✓"',
        color: 'transparent',
        backgroundColor: 'transparent',
        height: '24px',
        width: '24px',
        textAlign: 'center',
        fontSize: '26px',
        fontWeight: 500,
        lineHeight: '27px',
        display: 'inline-block',
        position: 'absolute',
        top: '46px',
        right: '12px',
        transition: '0.124s ease',
        speak: 'none'
      },
      '&.is-valid::after': {
        color: '#29CC81'
      }
    },
    errorIcon: {
      position: 'relative',
      '&::after': {
        content: '""',
        backgroundImage: 'url(/static/images/alert.svg)',
        backgroundSize: '20px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50%'
      }
    },
    textField: {
      display: 'block',
      height: '54px',
      border: '1px solid #dcdcdc',
      paddingRight: '36px',
      width: '100%',
      padding: theme.spacing(2)
    },
    label: {
      display: 'block',
      fontSize: '16px',
      lineHeight: '24px',
      marginBottom: theme.spacing(1),
      '&::after': {
        content: "' *'"
      }
    },
    error: {
      color: '#FE4343',
      display: 'block',
      marginTop: theme.spacing(1)
    }
  })

type OwnProps = {
  label: string
  name: string
  id: string
  value?: string
  handleTextFieldChange: (value: React.ChangeEvent<HTMLInputElement>) => any
  handleBlur: (value: React.ChangeEvent<HTMLInputElement>) => void
  validationError?: string
  autocomplete?: string
}

type Props = OwnProps & WithStyles<typeof styles>

const CustomTextField: React.FC<Props> = ({
  classes,
  label,
  name,
  id,
  value = '',
  handleTextFieldChange,
  handleBlur,
  validationError = '',
  autocomplete = 'on'
}) => {
  return (
    <div
      className={`${classes.root} ${
        validationError.length ? classes.errorIcon : ''
      }`}
    >
      <label className={classes.label} htmlFor={id}>
        {label}
      </label>
      <input
        autoComplete={autocomplete}
        className={classes.textField}
        value={value}
        type="text"
        id={id}
        name={name}
        onChange={e => handleTextFieldChange(e)}
        onBlur={e => handleBlur(e)}
      />
      {validationError.length > 1 && (
        <span className={classes.error}>{validationError}</span>
      )}
    </div>
  )
}

export default withStyles(styles)(CustomTextField)
