import { createMuiTheme } from '@material-ui/core/styles'

const defaultTheme = createMuiTheme()

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: '"Read", "Helvetica", "Arial", sans-serif',
    fontSize: 16,
    lineHeight: 24,
    fontWeightLight: 300,
    fontWeightRegular: 300,
    fontWeightMedium: 500
  },
  palette: {
    primary: {
      main: '#122AFF',
      dark: '#0d21cf'
    }
  },
  shape: {
    borderRadius: 0
  },
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The default props to change
      disableRipple: true, // No more ripple, on the whole application 💣!
      classes: {
        root: {
          backgroundColor: '#000'
        }
      }
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 0,
        padding: defaultTheme.spacing(4),
        paddingTop: '12px',
        paddingBottom: '12px',
        border: '1px solid #122AFF',
        fontWeight: 500,
        textTransform: 'uppercase',
        fontSize: '16px',
        lineHeight: '24px',
        outline: 'none',
        boxShadow: 'none',
        '&:hover': {
          cursor: 'pointer',
          boxShadow: 'none',
          backgroundColor: '#0d21cf'
        },
        [defaultTheme.breakpoints.down('sm')]: {
          padding: '12px 24px'
        }
      },
      label: {
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: '16px'
        }
      }
    }
  }
})

export default theme
