import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import {
  withStyles,
  Typography,
  Theme,
  WithStyles,
  createStyles
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import WidthLimiter from '../../sharedComponents/widthLimiter'
import Header from '../../sharedComponents/header'
import CustomHeroSearch from './customHeroSearch'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { LangType } from 'common/getLang'

const styles = (theme: Theme) =>
  createStyles({
    heroContainer: {
      position: 'relative',
      overflow: 'hidden',
      display: 'flex'
    },
    heroImageWrapper: {
      '&::before': {
        background:
          'linear-gradient(180deg, rgba(0, 0, 0, 0.15) 11.48%, rgba(0, 0, 0, 0) 28.38%), linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15))',
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 1,
        top: 0,
        left: 0,
        [theme.breakpoints.between('xs', 'sm')]: {
          background:
            'linear-gradient(180deg, rgba(0, 0, 0, 0.15) 25.48%, rgba(0, 0, 0, 0.25) 40.38%), linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0))'
        },
        [theme.breakpoints.down('xs')]: {
          background:
            'linear-gradient(180deg, rgba(0, 0, 0, 0.10) 20.48%, rgba(0, 0, 0, 0.35) 35.38%), linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0))'
        }
      }
    },
    heroImage: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      objectPosition: '57% 0',
      [theme.breakpoints.between('sm', 'md')]: {
        objectPosition: '50% 0'
      }
    },
    heroContentWrapper: {
      flexWrap: 'wrap',
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      minHeight: '74vh',
      maxWidth: '534px',
      width: '100%',
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(9),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3)
      },
      [theme.breakpoints.down('xs')]: {
        padding: 0
      }
    },
    heroContent: {
      position: 'relative',
      zIndex: 2,
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    heroHeading: {
      color: '#fff',
      fontSize: '62px',
      lineHeight: '68px',
      textTransform: 'uppercase',
      marginBottom: theme.spacing(3),
      fontWeight: 900,
      fontFamily: '"DaciaSpirit", "Helvetica", "Arial", sans-serif',
      textShadow: '0px 0px 56px rgba(0, 0, 0, 0.15)',
      [theme.breakpoints.down('sm')]: {
        fontSize: '42px',
        lineHeight: '48px'
      }
    },
    heroSubheading: {
      color: '#fff',
      fontSize: 26,
      fontWeight: 300,
      lineHeight: '34px',
      marginBottom: theme.spacing(1),
      textShadow: '0px 0px 16px rgba(0, 0, 0, 0.15)',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
        lineHeight: '24px',
        marginBottom: theme.spacing(2)
      }
    },
    heroDisclaimer: {
      color: '#fff',
      textShadow: '0px 0px 16px rgba(0, 0, 0, 0.15)',
      marginBottom: theme.spacing(4),
      fontSize: '18px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px'
      }
    },
    steps: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      padding: theme.spacing(3),
      paddingLeft: 0,
      paddingRight: 0,
      alignItems: 'center',
      width: '100%',
      backgroundColor: 'rgba(255,255,255,0.9)',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    stepLabel: {
      fontSize: '16px',
      fontWeight: 500,
      textTransform: 'uppercase'
    },
    stepList: {
      display: 'flex',
      flexWrap: 'wrap',
      counterReset: 'section',
      listStyle: 'none',
      paddingLeft: '24px'
    },
    stepListItem: {
      marginLeft: theme.spacing(3),
      flexGrow: 1,
      lineHeight: '26px',
      '&::before': {
        counterIncrement: 'section',
        content: 'counter(section)',
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        width: theme.spacing(3),
        height: theme.spacing(3),
        textAlign: 'center',
        display: 'inline-block',
        borderRadius: '100%',
        marginRight: theme.spacing(1)
      },
      '&:first-child': {
        marginLeft: 0
      }
    },
    searchNote: {
      color: '#fff',
      fontWeight: 300,
      textShadow: 'text-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15)'
    },
    searchNoteLink: {
      color: '#fff',
      fontWeight: 500
    },
    searchNoteSpan: {
      marginRight: theme.spacing(1)
    }
  })

type OwnProps = {
  lang: LangType
}

type Props = OwnProps & WithStyles<typeof styles>

const Hero: React.FC<Props> = ({ classes, lang }) => {
  const { t } = useTranslation()
  const headingArr = t('hero.heading').split('*')

  return (
    <section className={classes.heroContainer}>
      <div className={classes.heroContent}>
        <Header />
        <WidthLimiter>
          <div className={classes.heroContentWrapper}>
            <Typography
              variant="h2"
              component="h2"
              className={classes.heroHeading}
            >
              {headingArr[0]} {headingArr[1]}
              {headingArr[2]}{' '}
            </Typography>
            <Typography component="p" className={classes.heroSubheading}>
              {t('hero.subHeading')}
            </Typography>
            <Typography className={classes.heroDisclaimer}>
              {t('hero.disclaimer')}
            </Typography>
            <CustomHeroSearch lang={lang} />
            <p className={classes.searchNote}>
              <span className={classes.searchNoteSpan}>
                {t('hero.noRegistrationNumber')}
              </span>
              <Link
                smooth="true"
                to="#calculate-price"
                className={classes.searchNoteLink}
              >
                {t('hero.calculateAPriceSuggestion')}
              </Link>
            </p>
          </div>
        </WidthLimiter>
        <div className={classes.steps}>
          <Typography
            variant="body1"
            component="h3"
            className={classes.stepLabel}
          >
            {t('hero.stepLabel')}
          </Typography>
          <ol className={classes.stepList}>
            <li className={classes.stepListItem}>{t('hero.step1')}</li>
            <li className={classes.stepListItem}>{t('hero.step2')}</li>
            <li className={classes.stepListItem}>{t('hero.step3')}</li>
          </ol>
        </div>
      </div>
      <div className={classes.heroImageWrapper}>
        <LazyLoadImage
          srcSet="/static/images/mobile.jpg 600w,
                /static/images/13-tablet.jpg 1280w,
                /static/images/13-min.jpg 1920w"
          sizes="
               (min-width: 1281px) 1920px,
               (min-width: 600px) 1280px,
               100vw"
          src="/static/images/13-min-mobile.jpg"
          alt="Logo"
          className={classes.heroImage}
        />
      </div>
    </section>
  )
}

export default withStyles(styles)(Hero)
