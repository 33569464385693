import React from 'react'
import { Link } from 'react-router-dom'
import {
  withStyles,
  Typography,
  Fade,
  Theme,
  createStyles
} from '@material-ui/core'
import SectionHeading from '../../sharedComponents/sectionHeading'
import Img from 'react-image'
import { LangType } from 'common/getLang'
import { WithStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import ProductJson from '../../types/productJson'
import TagManager from 'react-gtm-module'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: '144px',
      [theme.breakpoints.down('sm')]: {
        paddingBottom: '12px'
      }
    },
    heading: {
      fontWeight: 300,
      marginBottom: theme.spacing(6),
      fontSize: 42,
      lineHeight: '48px',
      textAlign: 'center',
      display: 'block'
    },
    headingSpan: {
      fontWeight: 700,
      color: theme.palette.primary.main
    },
    subheading: {
      fontSize: 26,
      lineHeight: '34px'
    },
    contentWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    column1: {
      flex: '1 1 66.666%',
      minWidth: 300
    },
    column2: {
      flex: '1 1 33.333%',
      minWidth: 300
    },
    productImage: {
      maxWidth: '100%',
      mixBlendMode: 'multiply'
    },
    link: {
      display: 'inline-block',
      marginTop: '24px',
      color: theme.palette.primary.main,
      textDecoration: 'none',
      fontWeight: 300,
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    sectionHeading: {
      fontSize: '52px'
    },
    list: {
      borderTop: '1px solid #DCDCDC'
    },
    listItem: {
      borderBottom: '1px solid #DCDCDC',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      padding: theme.spacing(3),
      paddingLeft: 0,
      paddingRight: 0
    },
    key: {
      fontWeight: 500
    },
    paragraph: {
      fontSize: '26px',
      lineHeight: '32px',
      textAlign: 'center'
    }
  })

type OwnProps = {
  lang: LangType
  licensePlate: string
  description: string
  version: string
  inTraffic: string
  modelType: string
  color: string
  handleConfiguration: (value: ProductJson['configuration']) => void
  configuration: ProductJson['configuration']
  brand: string
  model: string
  commercialDescription: string
  errorMessage: string
  error: boolean
}

type Props = OwnProps & WithStyles<typeof styles>

const FindYourCar: React.FC<Props> = ({
  classes,
  licensePlate,
  description,
  version,
  inTraffic,
  modelType,
  color,
  lang,
  commercialDescription,
  brand,
  model,
  errorMessage,
  error
}) => {
  const { t } = useTranslation()

  const googleAccount = lang.locale === 'dk' ? 'UA-39005820-1' : 'UA-4728947-5'

  const tagManagerArgs = {
    dataLayer: {
      brand: 'Dacia',
      googleAccount: googleAccount,
      languageCode: lang.language.toUpperCase(),
      countryCode: lang.locale.toUpperCase(),
      pageName: 'Find-your-car',
      formName: 'service-contract-online',
      formType: 'service_contract_request',
      formCategory: 'lead_aftersales',
      formStepName: 'verify-car',
      formStepNumber: 'step-0',
      businessType: 'after-sales',
      pageType: 'mini-site',
      event: 'formOpen',
      vehicleCateogry: 'personal-cars'
    }
  }

  if (window.dataLayer) {
    TagManager.dataLayer(tagManagerArgs)
  }

  return (
    <Fade in={true} timeout={{ enter: 350, exit: 350 }}>
      <div className={classes.root}>
        <SectionHeading
          title={t('yourAgreement.step1.heading')}
          classes={{
            sectionHeading: classes.sectionHeading
          }}
          headingLvl="h1"
        />
        {error && errorMessage !== '' && (
          <p
            className={classes.paragraph}
            dangerouslySetInnerHTML={{
              __html: errorMessage
            }}
          />
        )}

        <div className={classes.contentWrapper}>
          <div className={classes.column1}>
            <Img
              className={classes.productImage}
              src={[
                `/static/images/vehicle-models/${modelType}-${color}.png`,
                `/static/images/vehicle-models/${modelType}.png`
              ]}
              alt={`${modelType} ${color}`}
            />
          </div>
          <div className={classes.column2}>
            <Typography
              variant="h4"
              component="h3"
              className={classes.subheading}
            >
              {commercialDescription}
            </Typography>
            <dl className={classes.list}>
              <div className={classes.listItem}>
                <dt className={classes.key}>{t('base.regNr')}</dt>
                <dd>{licensePlate}</dd>
              </div>
              <div className={classes.listItem}>
                <dt className={classes.key}>{t('base.version')}</dt>
                <dd>{version}</dd>
              </div>
              <div className={classes.listItem}>
                <dt className={classes.key}>
                  {t('yourAgreement.step1.inTraffic')}
                </dt>
                <dd>{inTraffic}</dd>
              </div>
              <Link to="/" className={classes.link}>
                {t('yourAgreement.step1.wrongCar')}
              </Link>
            </dl>
          </div>
        </div>
      </div>
    </Fade>
  )
}

export default withStyles(styles)(FindYourCar)
