import React from 'react'
import {
  withStyles,
  Typography,
  WithStyles,
  Theme,
  createStyles
} from '@material-ui/core'

const styles = (theme: Theme) =>
  createStyles({
    sectionHeading: {
      fontWeight: 900,
      marginBottom: theme.spacing(6),
      textTransform: 'uppercase',
      fontSize: 42,
      textAlign: 'center',
      fontFamily: '"DaciaSpirit", "Helvetica", "Arial", sans-serif',
      [theme.breakpoints.down('sm')]: {
        fontSize: '36px!important',
        lineHeight: '42px',
        marginBottom: theme.spacing(3)
      }
    },
    sectionHeadingSpan: {
      fontWeight: 900,
      color: theme.palette.primary.main
    }
  })

type OwnProps = {
  title: string
  headingLvl?: React.ElementType<React.HTMLAttributes<HTMLElement>>
}

type Props = OwnProps & WithStyles<typeof styles>

const sectionHeading: React.FC<Props> = ({
  classes,
  title,
  headingLvl = 'h2'
}) => (
  <Typography
    variant="h3"
    component={headingLvl}
    className={classes.sectionHeading}
  >
    {title.split('*')[0]}
    <span className={classes.sectionHeadingSpan}>{title.split('*')[1]}</span>
  </Typography>
)

export default withStyles(styles)(sectionHeading)
