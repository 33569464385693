import { createStyles } from '@material-ui/core/styles'

const styles = theme =>
  createStyles({
    root: {
      backgroundColor: '#FBFBFB',
      minHeight: '100vh'
    },
    header: {
      backgroundColor: '#fff',
      border: 'none',
      boxShadow: '0px 1px 0px #EEEEEE'
    },
    infoLink: {
      color: '#10314C',
      '& path': {
        stroke: '#10314C'
      }
    },
    headerTitle: {
      color: '#10314C'
    },
    nextButtonIcon: {
      marginRight: theme.spacing(1)
    },
    disabledButton: {
      cursor: 'default',
      pointerEvents: 'none',
      border: 'none',
      backgroundColor: '#DCDCDC',
      '& span': {
        color: '#FFFFFF'
      }
    },
    bottomBarRightPart: {
      display: 'flex',
      alignItems: 'center'
    },
    summary: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginRight: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        justifyContent: 'center'
      }
    },
    priceAmount: {
      fontSize: '26px',
      lineHeight: '34px',
      fontWeight: 'bold',
      paddingLeft: '6px',
      paddingRight: '3px'
    },
    backButton: {
      borderRadius: '100px',
      fontSize: '16px',
      lineHeight: '24px',
      padding: theme.spacing(4),
      paddingTop: '12px',
      paddingLeft: 0,
      paddingBottom: '12px',
      color: theme.palette.primary.main,
      backgroundColor: '#fff',
      border: '1px solid #fff',
      marginRight: theme.spacing(1),
      outline: 'none',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#fff'
      }
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    fixedBottomWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        position: 'relative',
        paddingTop: '48px'
      }
    },
    stepper: {
      backgroundColor: 'transparent',
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: '48px',
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(1)
      }
    },
    step: {
      '& svg': {
        color: '#DCDCDC'
      }
    },
    stepLabel: {
      fontWeight: '300!important',
      fontSize: 16,
      lineHeight: '24px',
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: '18px'
      }
    },
    connectorActive: {
      '& $connectorLine': {
        borderColor: theme.palette.primary.main
      }
    },
    connectorCompleted: {
      '& $connectorLine': {
        borderColor: theme.palette.primary.main
      }
    },
    connectorDisabled: {
      '& $connectorLine': {
        borderColor: '#F4F2F1'
      }
    },
    connectorLine: {
      transition: theme.transitions.create('border-color')
    },
    errormessage: {
      padding: theme.spacing(3),
      display: 'block',
      marginTop: theme.spacing(2),
      textAlign: 'left',
      position: 'relative',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.04)',
      backgroundColor: '#fff',
      borderRadius: '4px',
      '&::before': {
        content: "''",
        width: 0,
        height: 0,
        borderLeft: '8px solid transparent',
        borderRight: '8px solid transparent',
        borderBottom: '8px solid #fff',
        top: '-8px',
        left: '24px',
        position: 'absolute'
      }
    }
  })

export default styles
