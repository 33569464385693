import React from 'react'
import { withStyles, WithStyles, Theme, createStyles } from '@material-ui/core/styles'

const styles = (theme: Theme) => createStyles({
  widthLimiter: {
    width: '100%',
    maxWidth: 1272,
    margin: '0 auto',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  }
})

type Props = WithStyles<typeof styles>

const WidthLimiter: React.FC<Props> = props => (
  <div className={props.classes.widthLimiter}>{props.children}</div>
)

export default withStyles(styles)(WidthLimiter)
