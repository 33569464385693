import React, { useState, SyntheticEvent } from 'react'
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from '@material-ui/core/styles'
import Slider, { defaultValueReducer } from '@material-ui/lab/Slider'
import i18next from 'i18next'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflow: 'hidden',
      marginTop: theme.spacing(3)
    },
    sliderWrapper: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: '84px',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        paddingTop: 0
      }
    },
    slider: {
      display: 'inline-block',
      margin: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(3),
        width: '100%'
      }
    },
    sliderMin: {
      whiteSpace: 'nowrap',
      paddingBottom: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        order: 1,
        fontSize: '13px',
        lineHeight: '18px',
        paddingBottom: 0
      }
    },
    sliderMax: {
      whiteSpace: 'nowrap',
      paddingBottom: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        marginLeft: 'auto',
        order: 2,
        fontSize: '13px',
        lineHeight: '18px',
        paddingBottom: 0
      }
    },
    sliderContainer: {
      overflow: 'visible'
    },
    thumb: {
      outline: 'none',
      backgroundColor: '#fff',
      width: '48px',
      height: '48px',
      background: '50% no-repeat url(./static/images/draggable.svg)',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.06)',
      border: '1px solid #ddd',
      transition: '0.154s',
      [theme.breakpoints.down('sm')]: {
        width: '32px',
        height: '32px',
        backgroundSize: '16px'
      },
      '&::before': {
        content: `attr(aria-valuenow)" ${i18next.t('base.lengthUnit')}"`,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        border: '1px solid #122AFF',
        backgroundColor: '#fff',
        padding: theme.spacing(6),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        bottom: '60px',
        position: 'absolute',
        left: '50%',
        display: 'inline-block',
        transform: 'translateX(-50%)',
        [theme.breakpoints.down('sm')]: {
          content: 'none'
        }
      },
      '&::after': {
        content: '""',
        width: '12px',
        height: '12px',
        backgroundColor: '#fff',
        display: 'inline-block',
        position: 'absolute',
        bottom: '55px',
        left: '50%',
        transform: 'translateX(-50%) rotate(45deg)',
        boxShadow: '1px 1px 1px 0px #122AFF',
        [theme.breakpoints.down('sm')]: {
          content: 'none'
        }
      },
      '&:hover': {
        borderColor: theme.palette.primary.main,
        boxShadow: 'none'
      }
    },
    track: {
      height: '4px'
    },
    jumped: {
      boxShadow: 'none!important',
      borderColor: theme.palette.primary.main
    },
    sliderCurrentVal: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        width: '100%',
        textAlign: 'center'
      }
    }
  })

/**
 * a value reducer that will snap to multiple of 10 but also to the edge value
 * Useful here because the max=104 is not a multiple of 10
 */
function valueReducer(
  rawValue: number,
  props: any,
  event: SyntheticEvent<Element, Event>
) {
  const { disabled, max, min, step } = props

  function roundToStep(number: number) {
    return Math.round(number / step) * step
  }

  if (!disabled && step) {
    if (rawValue > min && rawValue < max) {
      if (rawValue === max - step) {
        // If moving the Slider using arrow keys and value is formerly an maximum edge value
        return roundToStep(rawValue + step / 2)
      }
      if (rawValue === min + step) {
        // Same for minimum edge value
        return roundToStep(rawValue - step / 2)
      }
      return roundToStep(rawValue)
    }
    return rawValue
  }

  return defaultValueReducer(rawValue, props, event)
}

/**
 * this slider has a max that is not a multiple of its step. We use a custom
 * `valueReducer` to adjust the given values
 */

type OwnProps = {
  handleSliderChange: (value: number) => void
  value: number
  min?: number
  max?: number
  step?: number
  unit?: string
}

type Props = OwnProps & WithStyles<typeof styles>

const StepSlider: React.FC<Props> = ({
  classes,
  handleSliderChange,
  value,
  min,
  max,
  step,
  unit
}) => {
  const [sliderValue, setSliderValue] = useState(value)

  return (
    <div className={classes.root}>
      <span className={classes.sliderCurrentVal}>
        {sliderValue} {unit}
      </span>
      <div className={classes.sliderWrapper}>
        <span className={classes.sliderMin}>
          {min} {unit}
        </span>
        <Slider
          value={sliderValue}
          valueReducer={valueReducer}
          min={min}
          max={max}
          step={step}
          onChange={(e, val) => {
            setSliderValue(val)
            if (handleSliderChange) {
              handleSliderChange(val)
            }
          }}
          className={classes.slider}
          classes={{
            thumb: classes.thumb,
            container: classes.sliderContainer,
            jumped: classes.jumped,
            activated: classes.jumped,
            track: classes.track
          }}
        />

        <span className={classes.sliderMax}>
          {max} {unit}
        </span>
      </div>
    </div>
  )
}

export default withStyles(styles)(StepSlider)
