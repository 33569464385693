const translations = {
  // Basic words and expression used through out the site
  'base.currency': 'SEK',
  'base.month': 'Month',
  'base.monthlyPayment': 'monthly payment',
  'base.yearlyPayment': 'early payment',
  'base.years': 'years',
  'base.from': 'From',
  'base.back': 'Back',
  'base.next': 'Next',
  'base.total': 'Total',
  'base.lengthUnit': 'km',
  'base.firstName': 'First name',
  'base.surname': 'Surname',
  'base.streetAddress': 'Street address',
  'base.city': 'City',
  'base.postalCode': 'Postal code',
  'base.email': 'Email',
  'base.phoneNumber': 'Phone number',
  'base.payment': 'Payment',
  'base.eInvoice': 'E-invoicing',
  'base.regNr': 'Reg.nr',
  'base.version': 'Version',
  'base.VAT': 'VAT',
  'base.VIN': 'VIN',
  'base.monthlyCostExVAT': 'Monthly cost excluding VAT',
  'base.monthlyCostIncVAT': 'Monthly cost including VAT',
  'base.personalIdentityNumber': 'Personal identity number',
  'base.personalIdentityNumberFormat': 'YYMMDD-XXXX',
  'base.period': 'period',
  'base.cookieText': 'Our website uses cookies. {{link}}',
  'base.cookieLinkText': 'Learn more about cookies',
  'base.cookieLinkUrl': 'https://www.dacia.dk/cookies/',
  'base.cookieBannerAccept': 'Accept',
  'base.totalMileage': 'Total mileage',
  'base.signContract': 'Sign contract',

  // Startpage
  // In .heading and .sectionHeadin the word(s) after the * is the blue part of headline
  'hero.heading': 'Get *Dacia Care',
  'hero.subHeading':
    'Dacia Care gets you full control of all your service costs. Find your deal today and sign directly with BankID.',
  'hero.aboutDaciaCare': 'About Dacia Care',
  'hero.aboutDaciaCareLink': 'https://www.dacia.dk/service/dacia-care/',
  'hero.searchLabel': 'Start by entering your registration number',
  'hero.noRegistrationNumber': 'No registration number?',
  'hero.calculateAPriceSuggestion': 'Calculate a price suggestion',
  'hero.searchButtonLabel': 'Find my Dacia',
  'hero.searchPlaceholder': 'ABC123',
  'hero.stepLabel': 'Get your dacia in 3 easy steps',
  'hero.step1': 'Find your Dacia',
  'hero.step2': 'Adjust your agreement',
  'hero.step3': 'Sign with BankId',
  'hero.notDacia':
    'The supplied registration number does not belong to a Dacia',
  'usps.sectionHeading': 'Benefits of *Dacia Care',
  'usps.focusOnYourDriving': 'Focus on your driving',
  'usps.focusOnYourDrivingText':
    'With presentive maintenence on the car you drive safely and smoothly and avoid unexpected expences.',
  'usps.controlYourCosts': 'Control your costs',
  'usps.controlYourCostsText':
    'With Dacia Care, you get full control over the cost of servicing, maintaining and repearing your car.',
  'usps.serviceWhereYouAre': 'Service where you are',
  'usps.serviceWhereYouAreText':
    'Naturally, it is our Dacia workshops that has the greatest knowledge of your car. </br> <a href="">See which one is closes to you</a>',
  'usps.seeWhichOneIsClosestToYou': 'See which one is closest to you',
  'usps.learnMoreAboutDaciaCare': 'Learn more about Dacia Care',
  'indicatePrice.heading': 'Calculate *your price',
  'indicatePrice.subHeading': 'Start by selecting your model',
  'adjustAgreement.adjustYourAgreement': 'Adjust *your agreement',
  'adjustAgreement.selectVersion': 'Select version',
  'adjustAgreement.numberYears': 'Number of years',
  'adjustAgreement.numberYearsTooltip':
    'Choose number of years you want Dacia Care to apply. You can at any time extend the contract period before the agreement expires by contacting Renault Finance Nordic.',
  'adjustAgreement.yearlyMileage': 'Yearly mileage',
  'adjustAgreement.yearlyMileageTooltip':
    'Choose the mileage you are expected to have per year. You can at any time extend the mileage or extend the contract period before the agreement expires by contacting Renault Finance Nordic AB.',
  'adjustAgreement.payment': 'Payment',
  'adjustAgreement.paymentTooltip':
    'Fill in the personal identification number of the person who is registered owner of the car in the Road Traffic Register. Your personal information is handled in accordance with the Personal Data Act.',
  'adjustAgreement.monthly': 'Monthly',
  'adjustAgreement.monthlyDescription': 'Free of interest and other expenses',
  'adjustAgreement.yearly': 'Yearly',
  'adjustAgreement.yearlyDescription': 'You pay for the whole period',
  'adjustAgreement.interestedInGettingDaciaCare':
    'Interested in getting Dacia Care?',
  'adjustAgreement.contactUs': 'Contact us',

  'footer.contactText': 'Kontakt os',
  'footer.contactLink': 'https://www.dacia.dk/om-dacia/kontakt-os/',
  'footer.cookieText': 'Cookies',
  'footer.cookieLink': 'https://www.dacia.dk/cookies/',
  // Your agreement page
  'yourAgreement.step1': 'Find your Dacia',
  'yourAgreement.step2': 'Adjust your agreement',
  'yourAgreement.step3': 'Sign your agreement',
  // Step 1
  'yourAgreement.step1.heading': 'Find your *Dacia',
  'yourAgreement.step1.inTraffic': 'In traffic',
  'yourAgreement.step1.wrongCar': 'Wrong car? Search again',
  // Step 2 not needed since same as adjust agreement on startpage
  // Step 3
  'yourAgreement.step3.heading': 'Sign *your agreement',
  'yourAgreement.step3.subHeading': 'Your information',
  'yourAgreement.step3.personalIdentityNumberTooltip':
    'Fill in the personal identification number of the person who is registered owner of the car in the Road Traffic Register. Your personal information is handled in accordance with the Personal Data Act.',
  'yourAgreement.step3.eInvoiceText':
    'The invoice for your first month is sent by email to {{email}}. From there you can choose your payment method and adjust your service agreement.',
  'yourAgreement.step3.eInvoiceText2ndPart':
    'Remember that you always have 30 days of withdrawal',
  'yourAgreement.step3.acceptNewsLetter':
    'I want to recive news and offers for Dacia from Renault Finance Nordic AB',
  'yourAgreement.step3.marketingConsent':
    '<a href="/">I accept Dacia\'s Service Agreement</a> for Renault Finance Nordic AB. *',
  'yourAgreement.step3.summary': 'Summary',
  'yourAgreement.step3.change': 'Change',
  'yourAgreement.step3.total': 'Total',
  'yourAgreement.step3.priceDescription':
    'Den viste pris er inklusive moms, det viste beløb er pr. Måned og uden renter og andre omkostninger',
  'yourAgreement.step3.signedHeading':
    'You have now signed your service agreement!',
  'yourAgreement.step3.signedSubHeading': 'What happens now?',
  'yourAgreement.step3.signedSubHeadingText': 'What happens now?',
  'yourAgreement.step3.signedText':
    'The invoice for your first month with Dacia Care is sent to you by email. From there you can choose your payment method and adjust your service agreement.</br>Book your annual service in any of our <a href="">150 Dacia certified workshops</a> all over Sweden.',
  'yourAgreement.step3.showAgreement': 'Show my service agreement',
  'yourAgreement.step3.termsAndConditions': 'Terms and conditions',
  'serviceAgreement.personalInformation': 'Personal information',
  'serviceAgreement.vehicleInfo': 'Vehicle information',
  'serviceAgreement.warrantyStartDate': 'Warranty start date',
  'serviceAgreement.periodNote':
    'The agreement ends when either the period expired or the total mileage has been reached.',
  'errors.mandatory': 'Field is mandatory',
  'errors.email': 'The email does not seem to be valid',
  'errors.personalIdentityNumber':
    'The personal identity number supplied seems to be invalid. It needs to be supplied in the format YYYYMMDDXXXX'
}

export default translations
