import i18n from 'i18next'
import translations from './translations'
import { Lang } from './getLang'

i18n.init({
  resources: {
    sv: {
      translations: translations.sv_SE
    },
    da: {
      translations: translations.da_DK
    }
  },
  fallbackLng: 'da',
  debug: true,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',
  lng: Lang.language,

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ','
  },

  react: {
    wait: false
  }
})

export default i18n
