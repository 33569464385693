import React from 'react'
import { withStyles, createStyles, Theme } from '@material-ui/core'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { WithStyles } from '@material-ui/styles'

const styles = (theme: Theme) =>
  createStyles({
    card: {
      border: '1px solid #F4F4F4',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.04)',
      borderRadius: 0,
      width: '100%',
      height: '100%',
      position: 'relative',
      backgroundColor: '#fff',
      padding: theme.spacing(3),
      transition: '0.3s ease',
      '&:hover': {
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        cursor: 'pointer'
      },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'stretch'
    },
    carImage: {
      width: 'calc(100% + 48px)',
      height: 'auto',
      margin: '-24px -24px 0 -24px',
      '&::before': {
        content: '""',
        height: '0',
        paddingTop: '66.919045%',
        display: 'block'
      }
    },
    subHeading: {
      fontFamily: '"DaciaSpirit", "Helvetica", "Arial", sans-serif',
      display: 'block',
      marginTop: 'auto',
      fontSize: 22,
      lineHeight: '28px',
      fontWeight: 900,
      textTransform: 'uppercase',
      marginBottom: theme.spacing(2),
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: '0',
        top: '0'
      }
    },
    meta: {
      marginBottom: theme.spacing(6)
    },
    label: {
      display: 'flex',
      flexDirection: 'column'
    },
    radio: {
      visibility: 'hidden',
      '&:checked': {
        position: 'absolute',
        left: '-9999px'
      },
      '&:not(:checked)': {
        position: 'absolute',
        left: '-9999px'
      },
      '&:checked + label': {
        width: '100%',
        cursor: 'pointer',
        lineHeight: '20px',
        left: 0,
        flexGrow: 1
      },
      '&:not(:checked) + label': {
        width: '100%',
        cursor: 'pointer',
        lineHeight: '20px',
        left: 0,
        flexGrow: 1
      },
      '&:checked + label:before': {
        content: "''",
        position: 'absolute',
        width: '24px',
        height: '24px',
        border: '1px solid #122AFF',
        borderRadius: '100%',
        background: '#fff',
        left: '50%',
        bottom: theme.spacing(3),
        transform: 'translateX(-50%)'
      },
      '&:not(:checked) + label:before': {
        content: "''",
        position: 'absolute',
        width: '24px',
        height: '24px',
        border: '1px solid #ddd',
        borderRadius: '100%',
        background: '#fff',
        left: '50%',
        bottom: theme.spacing(3),
        transform: 'translateX(-50%)'
      },
      '&:checked + label:after': {
        content: "''",
        width: '16px',
        height: '16px',
        background: theme.palette.primary.main,
        position: 'absolute',
        borderRadius: '100%',
        transition: 'all 0.2s ease',
        opacity: 1,
        left: '50%',
        bottom: 28,
        transform: 'translateX(-50%) scale(1)'
      },
      '&:not(:checked) + label:after': {
        content: "''",
        width: '16px',
        height: '16px',
        background: theme.palette.primary.main,
        position: 'absolute',
        borderRadius: '100%',
        transition: 'all 0.2s ease',
        opacity: 0,
        left: '50%',
        bottom: 28,
        transform: 'translateX(-50%) scale(0)'
      }
    }
  })

type OwnProps = {
  pricing: string
  url: string
  alt: string
  id: string
  title: string
  handleClick: () => void
}

type Props = OwnProps & WithStyles<typeof styles>

const ProductTile: React.FC<Props> = ({
  classes,
  title,
  pricing,
  url,
  alt,
  id,
  handleClick
}) => {
  return (
    <div className={classes.card}>
      <LazyLoadImage
        alt={alt}
        className={classes.carImage}
        src={url}
        height="1500"
        width="2000"
        effect="blur"
      />
      <input
        type="radio"
        id={id}
        name="cars"
        className={classes.radio}
        onClick={e => {
          handleClick()
        }}
      />
      <label htmlFor={id} className={classes.label}>
        <span className={classes.subHeading}>{title}</span>{' '}
        <span className={classes.meta}>{pricing}</span>
      </label>
    </div>
  )
}

export default withStyles(styles)(ProductTile)
