import React from 'react'
import { withStyles, WithStyles, Theme, createStyles } from '@material-ui/core'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#fff',
      padding: theme.spacing(2),
      borderRadius: 0,
      position: 'relative',
      minHeight: theme.spacing(9)
    },
    radio: {
      '&:checked': {
        position: 'absolute',
        left: '-9999px'
      },
      '&:not(:checked)': {
        position: 'absolute',
        left: '-9999px'
      },
      '& + label': {
        transition: '0.2s',
        border: '1px solid #DCDCDC'
      },
      '&:hover + label': {
        border: '1px solid #122AFF'
      },
      '&:checked + label': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        lineHeight: '20px',
        display: 'inline-block',
        left: 0,
        top: 0,
        border: '1px solid #122AFF'
      },
      '&:not(:checked) + label': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        lineHeight: '20px',
        display: 'inline-block',
        left: 0,
        top: 0
      },
      '&:checked + label:before': {
        content: "''",
        position: 'absolute',
        width: '24px',
        height: '24px',
        border: '1px solid #122AFF',
        borderRadius: '100%',
        background: '#fff',
        left: 12,
        top: '50%',
        transform: 'translateY(-50%)'
      },
      '&:not(:checked) + label:before': {
        content: "''",
        position: 'absolute',
        width: '24px',
        height: '24px',
        border: '1px solid #ddd',
        borderRadius: '100%',
        background: '#fff',
        left: 12,
        top: '50%',
        transform: 'translateY(-50%)'
      },
      '&:checked + label:after': {
        content: "''",
        width: '16px',
        height: '16px',
        background: theme.palette.primary.main,
        position: 'absolute',
        borderRadius: '100%',
        transition: 'all 0.2s ease',
        opacity: 1,
        left: 16,
        top: '50%',
        transform: 'translateY(-50%) scale(1)'
      },
      '&:not(:checked) + label:after': {
        content: "''",
        width: '16px',
        height: '16px',
        background: theme.palette.primary.main,
        position: 'absolute',
        borderRadius: '100%',
        transition: 'all 0.2s ease',
        opacity: 0,
        left: 16,
        top: '50%',
        transform: 'translateY(-50%) scale(0)'
      }
    },
    titleText: {
      paddingLeft: '36px',
      color: '#10314C',
      display: 'block',
      textAlign: 'left'
    },
    descriptionText: {
      paddingLeft: '36px',
      color: '#10314C',
      display: 'block',
      textAlign: 'left',
      fontSize: 12,
      lineHeight: '20px'
    },
    labelText: {
      position: 'absolute',
      color: '#10314C',
      right: theme.spacing(2),
      top: '50%',
      transform: 'translateY(-50%)'
    }
  })

type OwnProps = {
  label: string
  title?: string
  description?: string
  name: string
  id: string
  value: string
  handleChange: () => void
  defaultChecked: boolean
}

type Props = OwnProps & WithStyles<typeof styles>

const CustomRadioButton: React.FC<Props> = ({
  classes,
  label,
  title,
  description,
  name,
  id,
  value,
  handleChange,
  defaultChecked = false
}) => {
  return (
    <div className={classes.root}>
      {title && <span className={classes.titleText}>{title}</span>}
      {description && (
        <span className={classes.descriptionText}>{description}</span>
      )}
      <input
        className={classes.radio}
        type="radio"
        id={id}
        name={name}
        value={value}
        defaultChecked={defaultChecked}
        onChange={handleChange}
      />
      <label htmlFor={id}>
        <span className={classes.labelText}>{label}</span>
      </label>
    </div>
  )
}

export default withStyles(styles)(CustomRadioButton)
