const translations = {
  // Basic words and expression used through out the site
  'base.currency': 'kr',
  'base.month': 'Måned',
  'base.monthlyPayment': 'månedlige betaling',
  'base.yearlyPayment': 'årlig betaling',
  'base.years': 'år',
  'base.from': 'Fra',
  'base.back': 'Tilbage',
  'base.next': 'Næste',
  'base.total': 'Samlet',
  'base.lengthUnit': 'km',
  'base.firstName': 'Fornavn',
  'base.surname': 'Efternavn',
  'base.streetAddress': 'Adresse',
  'base.city': 'By',
  'base.postalCode': 'Postnummer',
  'base.email': 'E-mail',
  'base.phoneNumber': 'Telefonnummer',
  'base.payment': 'Betaling',
  'base.eInvoice': 'E-fakturering',
  'base.regNr': 'Registreringsnummer',
  'base.version': 'Version',
  'base.VAT': 'moms',
  'base.VIN': 'Stelnummer',
  'base.monthlyCostExVAT': 'Månedlig ydelse ekskl. moms',
  'base.monthlyCostIncVAT': 'Månedlig ydelse inkl. moms',
  'base.personalIdentityNumber': 'CPR-nummer',
  'base.personalIdentityNumberFormat': 'DDMMÅÅ-XXXX',
  'base.period': 'periode',
  'base.cookieBannerText': 'Vores hjemmeside bruger cookies. {{link}}',
  'base.cookieBannerLinkText': 'Få flere oplysninger om cookies',
  'base.cookieBannerLinkUrl':
    'https://webshop.dacia.dk/legal-information/cookies-dk.html',
  'base.cookieBannerAccept': 'Accepter',
  'base.totalMileage': 'Samlet kilometertal',
  'base.signContract': 'Underskriv med NemID',

  // Startpage
  // In .heading and .sectionHeadin the word(s) after the * is the blue part of headline
  'hero.heading': 'Få *Dacia Care',
  'hero.subHeading':
    'Med Dacia Care slipper du for uforudsete værkstedsregninger, og får fuldt overblik over dine service- og vedligeholdelsesomkostninger, allerede fra første service',
  'hero.aboutDaciaCare': 'Om Dacia Care',
  'hero.aboutDaciaCareLink': 'https://www.dacia.dk/service/dacia-care/',
  'hero.searchLabel': 'Start med at oplyse dit registreringsnummer',
  'hero.searchLabelTooltip':
    'Bilen skal være maksimalt 12 måneder gammel for at være berettiget til Dacia Care',
  'hero.noRegistrationNumber': 'Har du intet registreringsnummer?',
  'hero.calculateAPriceSuggestion': 'Udregn et prisforslag',
  'hero.searchButtonLabel': 'Find min Dacia',
  'hero.searchPlaceholder': 'AB12345',
  'hero.stepLabel': 'Få Dacia Care med 3 nemme trin',
  'hero.step1': 'Find din Dacia',
  'hero.step2': 'Tilpas din aftale',
  'hero.step3': 'Underskriv med NemID',
  'hero.error.notDacia':
    'Det medfølgende registreringsnummer tilhører ikke en Dacia',
  'hero.error.contactDealership':
    'Din Dacia er ældre end 8 måneder, derfor skal du kontakte <a target="_blank" href="https://www.dacia.dk/find-daciaforhandler/">din lokale Dacia forhandler</a> for at tegne en Dacia Care serviceaftale',
  'hero.error.tooOld':
    'Din Dacia er 12 måneder eller ældre, derfor kan du ikke længere tegne en Dacia Care serviceaftale til din Dacia',
  'hero.error.unexpected':
    'Noget uventet gik galt, prøv at indlæse siden eller prøv igen lidt senere',
  'hero.error.contractExists':
    'Der findes allerede en service- og vedligeholdelseskontrakt til denne bil.',
  'hero.error.noPrice':
    'Noget uventet er gået galt i søgningen. </br>Gå tilbage og prøv igen, hvis fejlen fortsætter, skal du kontakte <a  href="https://www.dacia.dk/om-dacia/kontakt-os/" >kundeservice</a>',
  'hero.disclaimer':
    'Dacia Care er en national service og reparationsaftale som omfatter 4 komponenter for en enkelt bil. Planlagt service, sliddele, udvidet fabriksgaranti og Dacia Assistance.',
  'usps.sectionHeading': 'Fordele med *Dacia Care',
  'usps.focusOnYourDriving': 'Fokusér på din kørsel',
  'usps.focusOnYourDrivingText':
    'Med forebyggende vedligeholdelse på bilen kører du sikkert og problemfrit, og undgår uventede udgifter',
  'usps.controlYourCosts': 'Få overblik over dine omkostninger',
  'usps.controlYourCostsText':
    'Med Dacia Care får du fuld overblik over omkostningerne ved service, vedligeholdelse og reparation af din bil.',
  'usps.serviceWhereYouAre': 'Service der hvor du er',
  'usps.serviceWhereYouAreText':
    'Det er vores Dacia værksteder der har den bedste viden om din bil.',
  'usps.seeWhichOneIsClosestToYouLink':
    'https://www.dacia.dk/find-daciaforhandler/',
  'usps.seeWhichOneIsClosestToYou': 'Se her, hvilken der er tættest på dig',
  'usps.learnMoreAboutDaciaCare': 'Lær mere om Dacia Care',
  'indicatePrice.heading': 'Udregn *din pris',
  'indicatePrice.subHeading': 'Start med at vælge din bilmodel',
  'adjustAgreement.adjustYourAgreement': 'Tilpas *din aftale',
  'adjustAgreement.selectVersion': 'Vælg version',
  'adjustAgreement.numberYears': 'Antal år',
  'adjustAgreement.numberYearsTooltip':
    'Vælg det antal år du vil have Dacia Care til at gælde. Du kan til enhver tid forlænge kontraktperioden før aftalen udløber ved at kontakte Renault Finance Nordic.',
  'adjustAgreement.yearlyMileage': 'Total antal kilometer',
  'adjustAgreement.yearlyMileageTooltip':
    'Vælg det kilometerantal du forventer at køre totalt. Du kan til enhver tid forlænge kilometerantallet eller forlænge kontraktperioden før aftalen udløber ved at kontakte Renault Finance Nordic.',
  'adjustAgreement.payment': 'Betaling',
  'adjustAgreement.paymentTooltip':
    'Udfyld CPR-nummer på den person der er registreret ejer af bilen i Dansk Motor Register. Din personlige information bliver behandlet i overensstemmelse med persondataloven.',
  'adjustAgreement.monthly': 'Månedlig',
  'adjustAgreement.monthlyDescription': 'Fri for renter og andre udgifter',
  'adjustAgreement.yearly': 'Årligt',
  'adjustAgreement.yearlyDescription': 'Du betaler for hele perioden',
  'adjustAgreement.interestedInGettingDaciaCare':
    'Er du interesseret i at få Dacia Care?',
  'adjustAgreement.contactUs': 'Kontakt os',

  'footer.privacyText': 'Privatlivspolitik',
  'footer.privacyLink': '/legal-information/privacy-policy-dk.html',
  'footer.cookieText': 'Cookies',
  'footer.cookieLink': '/legal-information/cookies-dk.html',
  'footer.copyright':
    '© Renault Finance Nordic bankfilial til RCI Banque SA Frankrig',

  // Your agreement page
  'yourAgreement.step1': 'Find din Dacia',
  'yourAgreement.step2': 'Tilpas din aftale',
  'yourAgreement.step3': 'Underskriv din aftale',
  // Step 1
  'yourAgreement.step1.heading': 'Find din *Dacia',
  'yourAgreement.step1.inTraffic': 'Indregistreret',
  'yourAgreement.step1.wrongCar': 'Forkert bil? Søg igen',
  // Step 2 not needed since same as adjust agreement on startpage
  // Step 3
  'yourAgreement.step3.heading': 'Underskriv *din aftale',
  'yourAgreement.step3.subHeading': 'Din information',
  'yourAgreement.step3.personalIdentityNumberTooltip':
    'Udfyld CPR-nummer på den person der er registreret ejer af bilen i Dansk Motor Register. Din personlige information bliver behandlet i overensstemmelse med persondataloven.',
  'yourAgreement.step3.eInvoiceText':
    'Fakturaen for din første måned bliver sendt via e-mail til {{email}}. Derfra kan du vælge din betalingsmetode og tilpasse din serviceaftale.',
  'yourAgreement.step3.eInvoiceText2ndPart':
    'Husk at du altid har 30 dages fortrydelsesret.',
  'yourAgreement.step3.acceptNewsLetter':
    'Ja, jeg vil gerne modtage nyheder og tilbud for Dacia fra Renault Group AB',
  'yourAgreement.step3.marketingConsent':
    ' Læs mere om aftalens <a href="/terms-and-conditions-dk.pdf" target="_blank">vilkår og betingelser for serviceaftale</a>.',
  'yourAgreement.step3.summary': 'Oversigt',
  'yourAgreement.step3.change': 'Ændre',
  'yourAgreement.step3.total': 'Total',
  'yourAgreement.step3.priceDescription':
    'Den viste pris er inklusiv moms, det viste beløb er pr. måned uden renter og andre omkostninger.',
  'yourAgreement.step3.signedHeading':
    'Du har nu underskrevet din serviceaftale!',
  'yourAgreement.step3.signedSubHeading': 'Hvad sker der nu?',
  'yourAgreement.step3.signedSubHeadingText': 'Hvad sker der nu?',
  'yourAgreement.step3.signedText':
    'Fakturaen for din første måned med Dacia Care bliver sendt til dig via e-mail. Derfra kan du vælge din betalingsmetode og tilpasse din serviceaftale. </br>Book dit årlige serviceeftersyn i enhver af vores <a href="">150 Dacia certificeret værksteder</a> over hele Danmark.',
  'yourAgreement.step3.showAgreement': 'Vis min serviceaftale',
  'yourAgreement.step3.termsAndConditions': 'Vilkår og betingelser',
  'yourAgreement.step3.createingContract': 'Opretter en kontrakt',
  'yourAgreement.step3.processingDetails':
    'Behandler data, dette kan tage et øjeblik...',
  'yourAgreement.step3.unexpectedIssue':
    'Noget uventet er gået galt i kontraktgenerering. </br>Gå tilbage og prøv igen, hvis fejlen fortsætter, skal du kontakte <a  href="https://www.dacia.dk/om-dacia/kontakt-os/" >kundeservice</a>',
  'yourAgreement.step3.generatedContract':
    'Din kontrakt er oprettet og er klar til at blive underskrevet.',
  'serviceAgreement.personalInformation': 'Personlig information',
  'serviceAgreement.vehicleInfo': 'Køretøjs information',
  'serviceAgreement.warrantyStartDate': 'Garanti startdato',
  'serviceAgreement.periodNote':
    'Aftalen slutter når enten perioden udløber eller det samlede kilometertal er nået.',
  'errors.mandatory': 'Felt er obligatorisk',
  'errors.email': 'Den medfølgende e-mail er i forkert format',
  'errors.personalIdentityNumber':
    'Det tasted CPR-nummer er ugyldigt. Det skal skrives således DDMMÅÅ-XXXX',
  'integrity.part1':
    'Dine personoplysninger behandles af Renault Finance Nordic bankfilial till RCI Banque SA Frankrike, Sverige og Renault Danmark, filial af Renault Nordic AB, Sverige. Du har ret til at anmode om adgang til dine oplysninger. Du kan også få dine oplysninger bekræftet eller slettet. Du kan gøre indsigelse mod behandlingen af dine oplysninger. Du kan endvidere anmode om en begrænsning af behandlingen af ​​dine oplysninger. Du kan anmode om kopi af dine oplysninger i et struktureret og almindeligt anvendt format.',
  'integrity.part2':
    'For at udøve ​​disse rettigheder skal du kontakte os med dokumentation for din identitet. Send en e-mail til privacy.nordic@renault.com, eller et brev til Renault Danmark, Industriparken 21 A 2750 Ballerup, eller udfyld formularen tilgængelig via dette <a href="http://www.dacia.dk/om-dacia/kontakt-os/" target="_blank">link.</a>',
  'integrity.part3':
    'For mere information om persondatabeskyttelse, følg venligst dette <a href="https://www.dacia.dk/integritetspolicy/" target="_blank">link.</a>'
}

export default translations
