import React from 'react'
import {
  withStyles,
  Tooltip,
  Grid,
  Slide,
  Theme,
  createStyles,
  WithStyles
} from '@material-ui/core/'
import SectionHeading from '../../sharedComponents/sectionHeading'
import CustomRadioButton from '../../sharedComponents/customRadioButton'
import StepSlider from '../../sharedComponents/stepSlider'
import WidthLimiter from '../../sharedComponents/widthLimiter'
import { ReactComponent as Info } from '../../common/images/icons/info-dark.svg'
import { debounce } from '../../common/debounce'
import ProductJson from 'types/productJson'
import { LangType } from 'common/getLang'
import i18next from 'i18next'
import TagManager from 'react-gtm-module'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: '144px',
      [theme.breakpoints.down('sm')]: {
        paddingBottom: '12px'
      }
    },
    heading: {
      fontWeight: 300,
      marginBottom: theme.spacing(3),
      fontSize: 42,
      lineHeight: '48px'
    },
    headingSpan: {
      fontWeight: 700,
      color: theme.palette.primary.main
    },
    kicker: {
      fontSize: 18,
      lineHeight: '26px',
      marginBottom: theme.spacing(3)
    },
    indicatePriceWrapper: {
      textAlign: 'center',
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(9),
      backgroundColor: '#F8F8F8'
    },
    fieldset: {
      border: 'none',
      padding: 0,
      margin: 0,
      marginBottom: theme.spacing(3)
    },
    legend: {
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(1),
      fontSize: 18,
      lineHeight: '26px'
    },
    tile: {
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.04)',
      backgroundColor: '#fff',
      borderRadius: '4px',
      marginTop: theme.spacing(3),
      padding: theme.spacing(3)
    },
    tooltip: {
      fontSize: 14,
      lineHeight: '22px',
      color: '#fff',
      backgroundColor: theme.palette.primary.dark,
      padding: theme.spacing(2),
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '12px',
        height: '12px',
        left: 'calc(50% + 2px)',
        bottom: '-10px',
        background: theme.palette.primary.dark,
        transform: 'rotate(45deg) translateX(-50%)'
      }
    },
    iconWrapper: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      '&::before': {
        position: 'absolute',
        content: '""',
        padding: '20px',
        marginLeft: '-10px',
        marginTop: '-10px'
      }
    },
    summary: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      marginTop: theme.spacing(6),
      borderTop: '1px solid #DCDCDC',
      borderBottom: '1px solid #DCDCDC'
    },
    widthLimiter: {
      maxWidth: 842
    },
    sectionHeading: {
      fontSize: '52px'
    }
  })

type State = {
  yearlyMileage: ProductJson['configuration']['yearlyMileage']
  numberOfYears: ProductJson['configuration']['numberOfYears']
  currentYears: number
  currentMileage: number
}

type OwnProps = {
  configuration: ProductJson['configuration']
  handleConfiguration: (value: ProductJson['configuration']) => void
  lang: LangType
}

type Props = OwnProps & WithStyles<typeof styles>

class AdjustYourAgreement extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      yearlyMileage: props.configuration.yearlyMileage,
      numberOfYears: props.configuration.numberOfYears,
      currentYears: props.configuration.currentYears,
      currentMileage: props.configuration.currentMileage
    }
    this.handleChangeOfYears = this.handleChangeOfYears.bind(this)
    this.handleChangeOfMileage = this.handleChangeOfMileage.bind(this)
  }

  handleChangeOfYears(id: number) {
    let elementPos = this.state.numberOfYears
      .map(function(x) {
        return x.id
      })
      .indexOf(id)
    let stateCopy = { ...this.state }
    for (let i = 0; i < this.state.numberOfYears.length; i++) {
      stateCopy.numberOfYears = stateCopy.numberOfYears.slice()
      stateCopy.numberOfYears[i] = Object.assign({}, stateCopy.numberOfYears[i])
      stateCopy.numberOfYears[i].defaultChecked = false
    }

    stateCopy.currentYears = parseInt(
      this.state.numberOfYears[elementPos].value
    )
    stateCopy.numberOfYears[elementPos].defaultChecked = true
    this.setState(stateCopy, () => {
      this.props.handleConfiguration(this.state)
    })
  }
  handleChangeOfMileage(value: number) {
    var yearlyMileage = { ...this.state.yearlyMileage }
    yearlyMileage.value = value
    this.setState(
      {
        yearlyMileage: yearlyMileage,
        currentMileage: value
      },
      () => {
        this.props.handleConfiguration(this.state)
      }
    )
  }
  componentDidMount() {
    if (window.dataLayer) {
      const googleAccount =
        this.props.lang.locale === 'dk' ? 'UA-39005820-1' : 'UA-4728947-5'

      const tagManagerArgs = {
        dataLayer: {
          brand: 'Dacia',
          googleAccount: googleAccount,
          languageCode: this.props.lang.language.toUpperCase(),
          countryCode: this.props.lang.locale.toUpperCase(),
          pageName: 'adjust-your-agreement',
          formName: 'service-contract-online',
          formType: 'service_contract_request',
          formCategory: 'lead_aftersales',
          formStepName: 'adjust-agreement',
          formStepNumber: 'step-1',
          businessType: 'after-sales',
          pageType: 'mini-site',
          event: 'formSteps',
          vehicleCateogry: 'personal-cars'
        }
      }
      TagManager.dataLayer(tagManagerArgs)
    }
  }
  render() {
    return (
      <Slide in={true} direction="left" timeout={{ enter: 350, exit: 350 }}>
        <div className={this.props.classes.root}>
          <SectionHeading
            title={i18next.t('adjustAgreement.adjustYourAgreement')}
            classes={{
              sectionHeading: this.props.classes.sectionHeading
            }}
            headingLvl="h1"
          />
          <WidthLimiter
            classes={{
              widthLimiter: this.props.classes.widthLimiter
            }}
          >
            <fieldset className={this.props.classes.fieldset}>
              <legend className={this.props.classes.legend}>
                {i18next.t('adjustAgreement.numberYears')}
                <Tooltip
                  enterTouchDelay={100}
                  classes={{ tooltip: this.props.classes.tooltip }}
                  title={i18next.t('adjustAgreement.numberYearsTooltip')}
                  placement="top"
                >
                  <span className={this.props.classes.iconWrapper}>
                    <Info />
                  </span>
                </Tooltip>
              </legend>
              <Grid container spacing={3}>
                {this.state.numberOfYears && this.state.numberOfYears.length > 0
                  ? this.state.numberOfYears.map(radio => (
                      <Grid key={radio.id} item xs={12} sm={12} md={4}>
                        <CustomRadioButton
                          label={radio.value + ' ' + i18next.t('base.years')}
                          name="years"
                          id={'year-' + radio.id}
                          value={radio.value}
                          defaultChecked={radio.defaultChecked}
                          handleChange={this.handleChangeOfYears.bind(
                            this,
                            radio.id
                          )}
                        />
                      </Grid>
                    ))
                  : ''}
              </Grid>
            </fieldset>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <fieldset className={this.props.classes.fieldset}>
                  <legend className={this.props.classes.legend}>
                    {i18next.t('adjustAgreement.yearlyMileage')}
                    <Tooltip
                      enterTouchDelay={100}
                      classes={{ tooltip: this.props.classes.tooltip }}
                      title={i18next.t('adjustAgreement.yearlyMileageTooltip')}
                      placement="top"
                    >
                      <span className={this.props.classes.iconWrapper}>
                        <Info />
                      </span>
                    </Tooltip>
                  </legend>
                  <StepSlider
                    value={this.state.yearlyMileage.value}
                    min={this.state.yearlyMileage.min}
                    max={this.state.yearlyMileage.max}
                    step={this.state.yearlyMileage.step}
                    unit={this.state.yearlyMileage.unit}
                    handleSliderChange={debounce((val: number) => {
                      this.handleChangeOfMileage(val)
                    }, 300)}
                  />
                </fieldset>
              </Grid>
            </Grid>
          </WidthLimiter>
        </div>
      </Slide>
    )
  }
}

export default withStyles(styles)(AdjustYourAgreement)
