import React from 'react'
import { withStyles, WithStyles, Theme, createStyles,  } from '@material-ui/core/styles'
import WidthLimiter from '../../sharedComponents/widthLimiter'

const styles = (theme: Theme) => createStyles({
  bottomBar: {
    position: 'sticky',
    bottom: 0,
    boxShadow: '0px -2px 8px rgba(0, 0, 0, 0.04)',
    width: '100%',
    padding: theme.spacing(3),
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: '#fff',
    zIndex: 99,
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      boxShadow: 'none',
      borderTop: '1px solid #dcdcdc'
    }
  }
})

type Props = WithStyles<typeof styles>

const BottomBar: React.FC<Props> = ({children, classes}) => (
  <div className={classes.bottomBar}>
    <WidthLimiter>{children}</WidthLimiter>
  </div>
)

export default withStyles(styles)(BottomBar)
