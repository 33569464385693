import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import {
  withStyles,
  CircularProgress,
  Fade,
  Tooltip,
  createStyles,
  Theme
} from '@material-ui/core'
import { ReactComponent as Info } from '../../common/images/icons/info.svg'
import { InputBase } from '@material-ui/core'
import { WithStyles } from '@material-ui/styles'
import { LangType } from '../../common/getLang'
import CarInfo from '../../types/carInfo'

const styles = (theme: Theme) =>
  createStyles({
    searchWrapper: {
      width: '100%'
    },
    inputWrapper: {
      display: 'flex',
      width: '100%'
    },
    searchLabel: {
      color: '#fff',
      display: 'block',
      textShadow: '0px 0px 56px rgba(0, 0, 0, 0.15)',
      fontSize: 20,
      lineHeight: '26px',
      fontWeight: 500,
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
        lineHeight: '24px'
      }
    },
    tooltip: {
      fontSize: 14,
      lineHeight: '22px',
      color: '#fff',
      backgroundColor: theme.palette.primary.dark,
      padding: theme.spacing(2),
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '12px',
        height: '12px',
        left: 'calc(50% + 2px)',
        bottom: '-10px',
        background: theme.palette.primary.dark,
        transform: 'rotate(45deg) translateX(-50%)'
      }
    },
    search: {
      background: '#FFFFFF',
      border: '1px solid rgba(0, 0, 0, 0.08)',
      boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)',
      borderRadius: 0,
      padding: theme.spacing(2),
      flexGrow: 1,
      fontWeight: 'bold',
      fontSize: '26px',
      width: '100%',
      maxWidth: 'calc(100% - 143px)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: 'calc(100% - 143px)',
        fontSize: '18px'
      },
      '&:focus': {
        outline: 'none'
      },
      '&::-webkit-input-placeholder': {
        color: '#dcdcdc'
      },
      '&::-moz-placeholder': {
        color: '#dcdcdc'
      },
      '&::-ms-input-placeholder': {
        color: '#dcdcdc'
      },
      '&:-moz-placeholder': {
        color: '#dcdcdc'
      }
    },
    searchButton: {
      position: 'relative',
      borderRadius: 0,
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      textTransform: 'uppercase',
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
      fontWeight: 500,
      transition: '0.2s ease',
      border: 'none',
      whiteSpace: 'nowrap',
      fontSize: '18px',
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        outline: 'none',
        cursor: 'pointer'
      },
      '&:focus': {
        outline: 'none',
        backgroundColor: theme.palette.primary.dark
      },
      [theme.breakpoints.down('sm')]: {
        whiteSpace: 'normal',
        fontSize: '16px',
        padding: '12px 24px'
      }
    },
    spinner: {
      color: '#fff'
    },
    iconWrapper: {
      position: 'relative',
      top: '3px',
      paddingLeft: theme.spacing(1),
      '&::before': {
        position: 'absolute',
        content: '""',
        padding: '20px',
        marginLeft: '-10px',
        marginTop: '-10px'
      }
    },
    errormessage: {
      padding: theme.spacing(3),
      display: 'block',
      marginTop: theme.spacing(2),
      textAlign: 'left',
      position: 'relative',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.04)',
      backgroundColor: '#fff',
      borderRadius: '4px',
      '&::before': {
        content: "''",
        width: 0,
        height: 0,
        borderLeft: '8px solid transparent',
        borderRight: '8px solid transparent',
        borderBottom: '8px solid #fff',
        top: '-8px',
        left: '24px',
        position: 'absolute'
      }
    }
  })

function calcDaysBetween(StartDate: Date, EndDate: Date) {
  // The number of milliseconds in all UTC days (no DST)
  const oneDay = 1000 * 60 * 60 * 24

  // A day in UTC always lasts 24 hours (unlike in other time formats)
  const start = Date.UTC(
    EndDate.getFullYear(),
    EndDate.getMonth(),
    EndDate.getDate()
  )
  const end = Date.UTC(
    StartDate.getFullYear(),
    StartDate.getMonth(),
    StartDate.getDate()
  )

  // so it's safe to divide by 24 hours
  return (start - end) / oneDay
}

type OwnProps = {
  lang: LangType
}

type Props = OwnProps & WithStyles<typeof styles>

const CustomHeroSearch: React.FC<Props> = ({ classes, lang }) => {
  const { t } = useTranslation()
  const [regnr, setRegnr] = useState('')
  const [redirect, setRedirect] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [regnrResponse, setRegnrResponse] = useState<CarInfo>()
  const [search, setSearch] = useState(false)
  const [regnrQuery, setRegnrQuery] = useState()

  const parseQuerySetRegNr = (key: string) => {
    var query = window.location.search.substring(1)
    var vars = query.split('&')
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=')
      if (
        decodeURIComponent(pair[0]) === key &&
        decodeURIComponent(pair[1]) !== 'undefined'
      ) {
        setRegnrQuery(decodeURIComponent(pair[1]))
      }
    }
  }

  useEffect(() => {
    if (regnrQuery) {
      setRegnr(regnrQuery.toUpperCase())
      setSearch(true)
    }
  }, [regnrQuery])

  // Set bearer headers
  useEffect(() => {
    const headers = {
      'Content-Type': 'application/json'
    }

    axios
      .post(
        '/api/auth',
        {
          user: process.env.REACT_APP_USR,
          password: process.env.REACT_APP_PSW
        },
        {
          headers: headers
        }
      )
      .then(res => {
        axios.defaults.headers.common = {
          Authorization: `Bearer ${res.data.token}`
        }
        parseQuerySetRegNr('regnumber')
      })
      .catch(function() {})
  }, [])

  useEffect(() => {
    if (!search) {
      return
    }
    setSearch(false)
    const fetchData = async () => {
      setIsLoading(true)
      setShowErrorMessage(false)
      await axios
        .post('/api/vehicle', {
          Language: lang.language,
          RegistrationNumber: regnr
        })
        .then(function(response) {
          const daysBetween = calcDaysBetween(
            new Date(response.data.firstRegistrationDate),
            new Date()
          )
          //If older than 1 year
          if (response.data.brand !== 'DACIA') {
            setErrorMessage(t('hero.error.notDacia'))
            setShowErrorMessage(true)
          } else if (daysBetween >= 366) {
            setErrorMessage(t('hero.error.tooOld'))
            setShowErrorMessage(true)
          } else if (response.data.existsInLyra) {
            setErrorMessage(t('hero.error.contractExists'))
            setShowErrorMessage(true)
          } else {
            setRegnrResponse(response.data)
          }
        })
        .catch(function(error) {
          if (error.response.data.errors) {
            error.response.data.errors.forEach(function(error: any) {
              if (error.code !== 'API407') {
                setErrorMessage(t('hero.error.unexpected'))
              } else {
                setErrorMessage(t('hero.error.notDacia'))
              }
            })
          } else {
            setErrorMessage(t('hero.error.unexpected'))
          }
          setShowErrorMessage(true)
        })
        .finally(function() {
          setIsLoading(false)
        })
    }
    fetchData()
  }, [search, lang.language, regnr, t])

  useEffect(() => {
    if (regnrResponse) {
      // Redirect if regnrResponse != undefined
      setRedirect(true)
    }
  }, [regnrResponse])

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: '/#/your-agreement',
          state: { carData: regnrResponse, regnr: regnr }
        }}
      />
    )
  }

  return (
    <form
      className={classes.searchWrapper}
      onSubmit={e => {
        e.preventDefault()
        setRegnr(regnr.trim())
        setSearch(true)
      }}
    >
      <label htmlFor="regsearch" className={classes.searchLabel}>
        {t('hero.searchLabel')}
        <Tooltip
          enterTouchDelay={100}
          classes={{ tooltip: classes.tooltip }}
          title={t('hero.searchLabelTooltip')}
          placement="top"
        >
          <span className={classes.iconWrapper}>
            <Info />
          </span>
        </Tooltip>
      </label>
      <div className={classes.inputWrapper}>
        <InputBase
          type="search"
          id="regsearch"
          className={classes.search}
          placeholder={t('hero.searchPlaceholder')}
          value={regnr ? regnr : ''}
          onChange={e => {
            e.target.value = e.target.value.toUpperCase()
            setRegnr(e.target.value)
          }}
        />

        <button className={classes.searchButton}>
          {!isLoading && <span>{t('hero.searchButtonLabel')}</span>}
          {isLoading && (
            <CircularProgress
              className={classes.spinner}
              size={36}
              thickness={4}
            />
          )}
        </button>
      </div>
      {showErrorMessage && (
        <Fade in={showErrorMessage} timeout={{ enter: 300, exit: 300 }}>
          <span
            className={classes.errormessage}
            dangerouslySetInnerHTML={{
              __html: errorMessage
            }}
          />
        </Fade>
      )}
    </form>
  )
}

export default withStyles(styles)(CustomHeroSearch)
