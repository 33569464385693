import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import {
  withStyles,
  CircularProgress,
  Theme,
  createStyles
} from '@material-ui/core'
import SectionHeading from '../../sharedComponents/sectionHeading'
import { ReactComponent as DocumentIcon } from '../../common/images/icons/document-icon.svg'
import { LangType } from 'common/getLang'
import { WithStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import PersonalInfo from 'types/personalInfo'
import ProductJson from 'types/productJson'
import TagManager from 'react-gtm-module'

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      textAlign: 'center',
      minHeight: '500px',
      marginBottom: '60px',
      [theme.breakpoints.down('sm')]: {
        position: 'static',
        boxShadow: 'none',
        borderTop: '1px solid #dcdcdc'
      }
    },
    sectionHeading: {
      fontSize: '52px'
    },
    iframe: {
      width: '100%',
      minHeight: '80vh'
    },
    errormessage: {
      fontSize: '20px',
      lineHeight: '1.3em'
    },
    paragraph: {
      fontSize: '26px',
      lineHeight: '32px'
    },
    button: {
      borderRadius: '100px',
      padding: theme.spacing(4),
      display: 'inline-block',
      marginTop: '6px',
      paddingTop: '12px',
      paddingBottom: '12px',
      border: '1px solid #122AFF',
      color: '#fff',
      backgroundColor: '#122AFF',
      textDecoration: 'none',
      textTransform: 'none',
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '1',
      outline: 'none',
      boxShadow: 'none',
      transition: '0.2s ease',
      '&:hover': {
        cursor: 'pointer',
        boxShadow: 'none',
        backgroundColor: '#129ade'
      }
    },
    buttonWrapper: {
      display: 'flex',
      alignItems: 'center'
    },
    icon: {
      paddingRight: '10px'
    }
  })

type OwnProps = {
  lang: LangType
  handleSigningError: () => void
  personalInfo: PersonalInfo
  configuration: ProductJson['configuration']
  licensePlate: string
  inTraffic: string
  vinCode: string
  priceId: string
  bearerToken: string
  description: string
}

type Props = OwnProps & WithStyles<typeof styles>

const SignYourAgreement: React.FC<Props> = ({
  licensePlate,
  personalInfo,
  configuration,
  inTraffic,
  vinCode,
  priceId,
  bearerToken,
  lang,
  description,
  classes,
  handleSigningError
}) => {
  const { t } = useTranslation()
  const [contractUrl, setContractUrl] = useState('')
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loadingMessage, setLoadingMessage] = useState(
    t('yourAgreement.step3.processingDetails')
  )
  const unexpectedError = t('yourAgreement.step3.unexpectedIssue')
  const createingContract = t('yourAgreement.step3.createingContract')

  // AllowDuplicateContract=true during testing

  const fetchContractUrl = useCallback(() => {
    var config = {
      headers: { Authorization: 'bearer ' + bearerToken }
    }

    axios
      .post(
        '/api/sign',
        {
          Language: lang.language,
          RegistrationNumber: licensePlate,
          Model: description,
          Vin: vinCode,
          InTraffic: inTraffic,
          ContractPeriod: configuration.currentYears * 12,
          Mileage: configuration.currentMileage,
          PriceId: priceId,
          AcceptNewsLetter: personalInfo.acceptNewsLetter,
          AllowDuplicateContract: false,
          Recipients: [
            {
              FirstName: personalInfo.firstName,
              LastName: personalInfo.lastName,
              SocialSecurityNumber: personalInfo.socialSecurityNumber,
              Address: personalInfo.address,
              City: personalInfo.city,
              ZipCode: personalInfo.zipCode,
              Email: personalInfo.email,
              Mobile: personalInfo.phoneNumber
            }
          ]
        },
        config
      )
      .then(response => {
        const documentUrl = response.data.recipients[0].document_url
        setLoadingMessage(createingContract)
        setContractUrl(documentUrl)
      })
      .catch(function(error) {
        console.log(error)
        setErrorMessage(unexpectedError)
        setError(true)

        handleSigningError()
      })
  }, [])

  useEffect(() => {
    fetchContractUrl()
    if (window.dataLayer) {
      const googleAccount =
        lang.locale === 'dk' ? 'UA-39005820-1' : 'UA-4728947-5'

      const tagManagerArgs = {
        dataLayer: {
          brand: 'Dacia',
          googleAccount: googleAccount,
          languageCode: lang.language.toUpperCase(),
          countryCode: lang.locale.toUpperCase(),
          pageName: 'create-contract',
          formName: 'service-contract-online',
          formType: 'service_contract_request',
          formCategory: 'lead_aftersales',
          formStepName: 'create-contract',
          formStepNumber: 'step-3',
          businessType: 'after-sales',
          pageType: 'mini-site',
          event: 'formSteps',
          vehicleCateogry: 'personal-cars'
        }
      }
      TagManager.dataLayer(tagManagerArgs)
    }
  }, [lang, fetchContractUrl])

  return (
    <div>
      <SectionHeading
        title={t('yourAgreement.step3.heading')}
        classes={{
          sectionHeading: classes.sectionHeading
        }}
        headingLvl="h1"
      />
      <div className={classes.wrapper}>
        {!contractUrl && !error && (
          <div>
            <p className={classes.paragraph}>{loadingMessage}</p>
            <CircularProgress size={36} thickness={4} />
          </div>
        )}
        {error && errorMessage !== '' && (
          <p
            className={classes.paragraph}
            dangerouslySetInnerHTML={{
              __html: errorMessage
            }}
          />
        )}
        {contractUrl && (
          <div>
            <p className={classes.paragraph}>
              {t('yourAgreement.step3.generatedContract')}
            </p>
            <p>
              <a className={classes.button} href={contractUrl}>
                <span className={classes.buttonWrapper}>
                  <span className={classes.icon}>
                    <DocumentIcon />
                  </span>
                  {t('base.signContract')}
                </span>
              </a>
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default withStyles(styles)(SignYourAgreement)
