import React, {
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  useCallback
} from 'react'
import {
  withStyles,
  Tooltip,
  Grid,
  Typography,
  Theme,
  createStyles
} from '@material-ui/core'
import WidthLimiter from '../../sharedComponents/widthLimiter'
import SectionHeading from '../../sharedComponents/sectionHeading'
import StepSlider from '../../sharedComponents/stepSlider'
import CustomRadioButton from '../../sharedComponents/customRadioButton'
import CustomSelect from '../../sharedComponents/customSelect'
import { convertMileage } from '../../common/convertMileage'
import ProductTile from './productTile'
import { ReactComponent as Info } from '../../common/images/icons/info-dark.svg'
import { debounce } from '../../common/debounce'
import axios from 'axios'
import { LangType } from 'common/getLang'
import { WithStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import ProductJson from '../../types/productJson'

const styles = (theme: Theme) =>
  createStyles({
    kicker: {
      fontSize: 18,
      fontWeight: 300,
      lineHeight: '26px',
      marginTop: '-12px',
      marginBottom: theme.spacing(3)
    },
    indicatePriceWrapper: {
      textAlign: 'center',
      paddingTop: '96px',
      paddingBottom: '96px',
      backgroundColor: '#F8F8F8',
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(4)
      }
    },
    fieldset: {
      border: 'none',
      padding: 0,
      margin: 0,
      marginBottom: theme.spacing(3)
    },
    legend: {
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(1),
      fontSize: 18,
      lineHeight: '26px'
    },
    tile: {
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.04)',
      backgroundColor: '#fff',
      borderRadius: 0,
      marginTop: theme.spacing(3),
      paddingTop: '96px',
      paddingBottom: '96px',
      outline: 'none'
    },
    tooltip: {
      fontSize: 14,
      lineHeight: '22px',
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(2),
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '12px',
        height: '12px',
        left: 'calc(50% + 2px)',
        bottom: '-10px',
        background: theme.palette.primary.main,
        transform: 'rotate(45deg) translateX(-50%)'
      }
    },
    iconWrapper: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      '&::before': {
        position: 'absolute',
        content: '""',
        padding: '20px',
        marginLeft: '-10px',
        marginTop: '-10px'
      }
    },
    summary: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      marginTop: theme.spacing(6),
      borderTop: '1px solid #DCDCDC',
      borderBottom: '1px solid #DCDCDC'
    },
    priceAmount: {
      fontSize: '26px',
      lineHeight: '34px',
      fontWeight: 500,
      paddingLeft: '3px',
      paddingRight: '3px'
    },
    widthLimiter: {
      maxWidth: 842
    },
    contactText: {
      marginTop: '48px',
      fontSize: '26px',
      lineHeight: '34px'
    },
    button: {
      borderRadius: '100px',
      padding: theme.spacing(4),
      display: 'inline-block',
      marginTop: '24px',
      paddingTop: '12px',
      paddingBottom: '12px',
      border: '1px solid #122AFF',
      color: '#fff',
      backgroundColor: '#122AFF',
      textDecoration: 'none',
      textTransform: 'none',
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '26px',
      outline: 'none',
      boxShadow: 'none',
      transition: '0.2s ease',
      '&:hover': {
        cursor: 'pointer',
        boxShadow: 'none',
        backgroundColor: '#129ade'
      }
    },
    grid: {
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        whiteSpace: 'nowrap',
        overflowX: 'scroll'
      }
    },
    gridItem: {
      [theme.breakpoints.down('sm')]: {
        display: 'inline-block',
        maxWidth: 'calc(100% - 100px)'
      },
      '&:first-child': {
        [theme.breakpoints.down('sm')]: {
          marginLeft: '50px'
        }
      },
      '&:last-child': {
        [theme.breakpoints.down('sm')]: {
          marginRight: '50px'
        }
      }
    }
  })

type OwnProps = {
  lang: LangType
  json: ProductJson
}

type Props = OwnProps & WithStyles<typeof styles>

const IndicatePrice: React.FC<Props> = ({ classes, json, lang }) => {
  const products = json.products
  const { t } = useTranslation()
  const configuration = json.configuration
  const [carChoosen, setCarChoosen] = useState(false)
  const [price, setPrice] = useState<number>()
  const [years, setYears] = useState<number>()
  const [mileage, setMileage] = useState<number>()
  const [model, setModel] = useState('')
  const [activeCar, setActiveCar] = useState()
  const priceAdjustmentWrapper = useRef<HTMLDivElement>(null)

  const updatePrice = useCallback(() => {
    if (years && mileage && model) {
      axios
        .post('/api/price', {
          Language: lang.language,
          ContractPeriod: years * 12,
          Mileage: convertMileage(mileage, years),
          Model: model
        })
        .then(function(response) {
          setPrice(
            (response.data.basePrice.priceIncVat as number) / (years * 12)
          )
        })
        .catch(function(error) {
          console.log(error)
        })
    }
  }, [lang.language, mileage, model, years])

  useEffect(() => {
    setYears(configuration.currentYears)
    setMileage(configuration.yearlyMileage.value)
  }, [configuration.currentYears, configuration.yearlyMileage.value])

  useEffect(() => {
    if (carChoosen && model) {
      if (
        priceAdjustmentWrapper !== null &&
        priceAdjustmentWrapper.current !== null
      ) {
        priceAdjustmentWrapper.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    }
  }, [carChoosen, model])

  useEffect(() => {
    updatePrice()
  }, [years, mileage, model, updatePrice])

  function handleClick(activeCar: any) {
    setCarChoosen(true)
    setActiveCar(activeCar)
    //Set current model and version
    setModel(`${activeCar.value.toUpperCase()} ${activeCar.versions[0].name}`)
  }
  function handleChangeOfYears(val: number) {
    setYears(val)
  }
  function handleChangeOfMileage(val: number) {
    setMileage(val)
  }
  function handleChangeOfModel(e: ChangeEvent<HTMLOptionElement>) {
    //Set current model and version
    setModel(`${activeCar.value.toUpperCase()} ${e.target.value}`)
    updatePrice()
  }

  return (
    <div className={classes.indicatePriceWrapper} id="calculate-price">
      <WidthLimiter>
        <SectionHeading title={t('indicatePrice.heading')} />
        <Typography component="p" className={classes.kicker}>
          {t('indicatePrice.subHeading')}
        </Typography>
        <Grid container spacing={3} className={classes.grid}>
          {products && products.length > 0
            ? products.map(product => (
                <Grid
                  className={classes.gridItem}
                  key={product.id}
                  item
                  xs={12}
                  sm={12}
                  md={4}
                >
                  <ProductTile
                    title={product.name}
                    pricing={product.pricing}
                    url={product.url}
                    alt={product.alt}
                    id={'car-' + product.id}
                    handleClick={() => {
                      handleClick(product)
                    }}
                  />
                </Grid>
              ))
            : ''}
        </Grid>
        {carChoosen && (
          <div
            ref={priceAdjustmentWrapper}
            tabIndex={0}
            className={classes.tile}
          >
            <WidthLimiter
              classes={{
                widthLimiter: classes.widthLimiter
              }}
            >
              <SectionHeading title={t('hero.step2')} />
              <fieldset className={classes.fieldset}>
                <legend className={classes.legend}>
                  {t('adjustAgreement.selectVersion')}
                </legend>
                <CustomSelect
                  label="Select version"
                  options={activeCar ? activeCar.versions : []}
                  name="versionSelect"
                  id="versionSelect"
                  handleSelectChange={handleChangeOfModel}
                />
              </fieldset>
              <fieldset className={classes.fieldset}>
                <legend className={classes.legend}>
                  {t('adjustAgreement.numberYears')}
                  <Tooltip
                    enterTouchDelay={100}
                    classes={{ tooltip: classes.tooltip }}
                    title={t('adjustAgreement.numberYearsTooltip')}
                    placement="top"
                  >
                    <span className={classes.iconWrapper}>
                      <Info />
                    </span>
                  </Tooltip>
                </legend>
                <Grid container spacing={3}>
                  {configuration.numberOfYears &&
                  configuration.numberOfYears.length > 0
                    ? configuration.numberOfYears.map(radio => (
                        <Grid key={radio.id} item xs={12} sm={12} md={4}>
                          <CustomRadioButton
                            label={radio.value + ' ' + t('base.years')}
                            name="years"
                            id={'year-' + radio.id}
                            value={radio.value}
                            defaultChecked={radio.defaultChecked}
                            handleChange={() =>
                              handleChangeOfYears(parseInt(radio.value))
                            }
                          />
                        </Grid>
                      ))
                    : ''}
                </Grid>
              </fieldset>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <fieldset className={classes.fieldset}>
                    <legend className={classes.legend}>
                      {t('adjustAgreement.yearlyMileage')}
                      <Tooltip
                        enterTouchDelay={100}
                        classes={{ tooltip: classes.tooltip }}
                        title={t('adjustAgreement.yearlyMileageTooltip')}
                        placement="top"
                      >
                        <span className={classes.iconWrapper}>
                          <Info />
                        </span>
                      </Tooltip>
                    </legend>
                    <StepSlider
                      value={configuration.yearlyMileage.value}
                      min={configuration.yearlyMileage.min}
                      max={configuration.yearlyMileage.max}
                      step={configuration.yearlyMileage.step}
                      unit={configuration.yearlyMileage.unit}
                      handleSliderChange={debounce((val: number) => {
                        handleChangeOfMileage(val)
                      }, 300)}
                    />
                  </fieldset>
                </Grid>
              </Grid>
              {price && (
                <div className={classes.summary}>
                  <span>
                    {t('base.total')}{' '}
                    <b className={classes.priceAmount}>
                      {price} {t('base.currency')}
                    </b>
                    /{t('base.month')}
                  </span>
                </div>
              )}
            </WidthLimiter>
          </div>
        )}
      </WidthLimiter>
    </div>
  )
}

export default withStyles(styles)(IndicatePrice)
