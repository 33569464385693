import React from 'react'
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core'

const styles = (theme: Theme) => createStyles({
  checkboxWrapper: {
    position: 'relative',
    display: 'block',
    marginBottom: theme.spacing(1),
    overflow: 'hidden'
  },
  checkbox: {
    '&:checked': {
      position: 'absolute',
      left: '-9999px'
    },
    '&:not(:checked)': {
      position: 'absolute',
      left: '-9999px'
    },
    '& + label': {
      border: '1px solid transparent',
      transition: '0.154s',
      borderRadius: '8px'
    },
    '&:checked + label:before': {
      content: "''",
      position: 'absolute',
      width: '18px',
      height: '18px',
      border: '1px solid #ddd',
      borderRadius: '4px',
      background: '#fff',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)'
    },
    '&:not(:checked) + label:before': {
      content: "''",
      position: 'absolute',
      width: '18px',
      height: '18px',
      border: '1px solid #ddd',
      borderRadius: '4px',
      background: '#fff',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)'
    },
    '&:checked + label:after': {
      content: "'✓'",
      width: '12px',
      height: '24px',
      marginLeft: '1px',
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: 700,
      position: 'absolute',
      transition: 'all 0.154s ease',
      color: theme.palette.primary.main,
      opacity: 1,
      top: '50%',
      transform: 'translateY(-50%) scale(1)'
    },
    '&:not(:checked) + label:after': {
      content: "''",
      width: '12px',
      height: '12px',
      position: 'absolute',
      transition: 'all 0.154s ease',
      opacity: 0,
      top: '50%',
      transform: 'translateY(-50%) scale(0)'
    }
  },
  labelText: {
    display: 'block',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#10314C',
    paddingLeft: theme.spacing(4)
  },
  error: {
    color: 'red',
    display: 'block',
    marginTop: theme.spacing(1)
  }
})

type OwnProps = {
  label: string
  name: string
  id: string
  value?: string
  handleChange?: (value: React.ChangeEvent<HTMLInputElement>) => void
  validationError?: string
  defaultChecked?: boolean
}

type Props = OwnProps & WithStyles<typeof styles>

const CustomCheckbox: React.FC<Props> = ({
  classes,
  label,
  name,
  id,
  value,
  handleChange,
  validationError = '',
  defaultChecked = false
}) => {
  return (
    <div >
      <div className={classes.checkboxWrapper}>
        <input
          className={classes.checkbox}
          type="checkbox"
          id={id}
          name={name}
          value={value}
          onChange={handleChange}
          defaultChecked={defaultChecked}
        />
        <label htmlFor={id} >
          <span
            className={classes.labelText}
            dangerouslySetInnerHTML={{
              __html: label
            }}
          />
        </label>
      </div>
      {validationError.length > 0 && (
        <span className={classes.error}>{validationError}</span>
      )}
    </div>
  )
}



export default withStyles(styles)(CustomCheckbox)
