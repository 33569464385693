import React, { useEffect, useState } from 'react'
import { Fade, CircularProgress, withStyles, Collapse, createStyles } from '@material-ui/core'
import axios, { AxiosResponse } from 'axios'
import Hero from './hero'
import Usps from './usps'
import CookieBanner from '../../sharedComponents/cookieBanner'
import WidthLimiter from '../../sharedComponents/widthLimiter'
import Footer from '../../sharedComponents/footer'
import IndicatePrice from './indicatePrice'
import { LangType } from 'common/getLang'
import { WithStyles } from '@material-ui/styles'
import ProductJson from 'types/productJson';

const styles = () => createStyles({
  root: {},
  wrapper: {
    paddingTop: '96px',
    paddingBottom: '96px',
    backgroundColor: '#F8F8F8',
    display: 'inline-block',
    width: '100%'
  },
  innerWrapper: {
    textAlign: 'center'
  }
})

type OwnProps = {
  lang: LangType,
}

type Props = OwnProps & WithStyles<typeof styles>

const Home: React.FC<Props> = ({ lang, classes }) => {
  const [json, setJson] = useState<ProductJson>() //This was set to false, IndicatePrice did not approve...

  useEffect(() => {
    axios.get<AxiosResponse<ProductJson>>(`../data-${lang.locale}.json`).then(resp => {
      setJson(resp.data)
    })
  }, [lang])

  return (
    <Fade in={true} timeout={{ enter: 500, exit: 500 }}>
      <div>
        <CookieBanner />
        <Hero lang={lang} />
        <Usps />
        {json && (
          <Collapse in={true} timeout={{ enter: 500, exit: 500 }}>
            <IndicatePrice json={json} lang={lang} />
          </Collapse>
        )}
        {!json && (
          <Fade in={true} timeout={{ enter: 0, exit: 500 }}>
            <div className={classes.wrapper}>
              <WidthLimiter>
                <div className={classes.innerWrapper}>
                  <CircularProgress size={36} thickness={4} />
                </div>
              </WidthLimiter>
            </div>
          </Fade>
        )}
        <Footer />
      </div>
    </Fade>
  )
}

export default withStyles(styles)(Home)
