import React from 'react'
import { withStyles, WithStyles, Theme, createStyles } from '@material-ui/core'
import { ReactComponent as ArrowDown } from '../common/images/icons/chevron-down.svg'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      position: 'relative',
      height: '72px',
      lineHeight: '1',
      verticalAlign: 'middle'
    },
    label: {
      position: 'absolute !important' as 'absolute',
      height: '1px',
      width: '1px',
      clip: 'rect(1px, 1px, 1px, 1px)'
    },
    select: {
      position: 'relative',
      zIndex: 2,
      width: '100%',
      padding: '0 48px 0 12px',
      border: 'none',
      background: 'transparent',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      height: '100%',
      fontSize: '16px',
      fontWeight: 300,
      lineHeight: '1.5'
    },
    customSelect: {
      position: 'absolute',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      zIndex: 1,
      borderRadius: 0,
      border: '1px solid #DCDCDC',
      transition: '0.154s',
      '&:hover': {
        borderColor: theme.palette.primary.main
      }
    },
    iconWrapper: {
      width: '32px',
      height: '32px',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: '6px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    icon: {
      fill: '#10314C'
    }
  })

type OwnProps = {
  label: string
  options: Array<any>
  name: string
  id: string
  handleSelectChange: any
}

type Props = OwnProps & WithStyles<typeof styles>

const CustomSelect: React.FC<Props> = ({
  classes,
  label,
  options,
  name,
  id,
  handleSelectChange
}) => {
  return (
    <div className={classes.root}>
      <label className={classes.label} htmlFor={id}>
        {label}
      </label>
      <div className={classes.customSelect}>
        <select
          id={id}
          name={name}
          className={classes.select}
          onChange={handleSelectChange}
        >
          {options.map(
            (
              option: {
                id: string | number | undefined
                value: string | number | string[] | undefined
                name: React.ReactNode
              },
              i: any
            ) => (
              <option key={option.id} value={option.value}>
                {option.name}
              </option>
            )
          )}
        </select>
        <div className={classes.iconWrapper}>
          <ArrowDown className={classes.icon} />
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(CustomSelect)
