import React from 'react'
import {
  withStyles,
  Typography,
  WithStyles,
  Theme,
  createStyles
} from '@material-ui/core'
import WidthLimiter from './widthLimiter'
import { useTranslation } from 'react-i18next'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(3),
      paddingLeft: 0,
      paddingRight: 0
    },
    footerContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'block'
      }
    },
    linkList: {
      listStyle: 'none',
      display: 'flex',
      paddingLeft: 0,
      marginLeft: 0
    },
    listItem: {
      borderRight: '1px solid rgba(255,255,255,0.5)',
      paddingRight: theme.spacing(2),
      marginRight: theme.spacing(2),
      '&:last-child': {
        borderRight: 'none',
        paddingRight: 0,
        marginRight: 0
      }
    },
    link: {
      color: '#fff',
      textDecoration: 'none',
      fontSize: '16px',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    copyright: {
      color: '#fff',
      fontSize: '16px'
    }
  })

type Props = WithStyles<typeof styles>

const Footer: React.FC<Props> = ({ classes }) => {
  const { t } = useTranslation()
  return (
    <div className={classes.root}>
      <WidthLimiter>
        <div className={classes.footerContent}>
          <div>
            <Typography component="p" className={classes.copyright}>
              {t('footer.copyright')} {new Date().getFullYear()}
            </Typography>
          </div>
          <ul className={classes.linkList}>
            <li className={classes.listItem}>
              <a
                href={t('footer.privacyLink')}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                {t('footer.privacyText')}
              </a>
            </li>
            <li className={classes.listItem}>
              <a
                href={t('footer.cookieLink')}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                {t('footer.cookieText')}
              </a>
            </li>
          </ul>
        </div>
      </WidthLimiter>
    </div>
  )
}

export default withStyles(styles)(Footer)
