import React from 'react'
import { withStyles, Theme, createStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import WidthLimiter from '../../sharedComponents/widthLimiter'
import SectionHeading from '../../sharedComponents/sectionHeading'
import { ReactComponent as Car } from '../../common/images/icons/DigitalPictogram_ACCELERATION.svg'
import { ReactComponent as Money } from '../../common/images/icons/DigitalPictogram_MONEY.svg'
import { ReactComponent as Road } from '../../common/images/icons/DigitalPictogram_MOTORWAY.svg'
import { ReactComponent as ArrowRight } from '../../common/images/icons/chevron-right.svg'
import { WithStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'

const styles = (theme: Theme) =>
  createStyles({
    heading: {
      fontWeight: 300,
      marginBottom: theme.spacing(6),
      fontSize: 42,
      lineHeight: '48px'
    },
    headingSpan: {
      fontWeight: 700,
      color: theme.palette.primary.main
    },
    iconWrapper: {
      height: theme.spacing(9),
      width: theme.spacing(9),
      margin: '0 auto',
      display: 'inline-block'
    },
    arrowIconWrapper: {
      height: theme.spacing(2),
      width: theme.spacing(2),
      marginLeft: theme.spacing(1),
      display: 'inline-block'
    },
    icon: {
      fill: theme.palette.primary.main
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    blockLink: {
      display: 'block',
      fontSize: 18,
      lineHeight: '26px',
      marginTop: theme.spacing(9),
      color: theme.palette.primary.main,
      textDecoration: 'none',
      fontWeight: 500,
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    uspsWrapper: {
      textAlign: 'center',
      paddingTop: '96px',
      paddingBottom: '96px',
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(4)
      }
    },
    subheading: {
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '26px',
      marginBottom: theme.spacing(1)
    },
    promoText: {
      fontSize: 18,
      lineHeight: '26px',
      fontWeight: 300,
      color: '#10314C'
    }
  })

type Props = WithStyles<typeof styles>

const Usps: React.FC<Props> = ({ classes }) => {
  const { t } = useTranslation()

  return (
    <div className={classes.uspsWrapper}>
      <WidthLimiter>
        <SectionHeading title={t('usps.sectionHeading')} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={4}>
            <div className={classes.iconWrapper}>
              <Car className={classes.icon} />
            </div>
            <Typography component="h3" className={classes.subheading}>
              {t('usps.focusOnYourDriving')}
            </Typography>
            <Typography component="p" className={classes.promoText}>
              {t('usps.focusOnYourDrivingText')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div className={classes.iconWrapper}>
              <Money className={classes.icon} />
            </div>
            <Typography component="h3" className={classes.subheading}>
              {t('usps.controlYourCosts')}
            </Typography>
            <Typography component="p" className={classes.promoText}>
              {t('usps.controlYourCostsText')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div className={classes.iconWrapper}>
              <Road className={classes.icon} />
            </div>
            <Typography component="h3" className={classes.subheading}>
              {t('usps.serviceWhereYouAre')}
            </Typography>
            <Typography component="p" className={classes.promoText}>
              <span>{t('usps.serviceWhereYouAreText')}</span>
              <br />
              <a
                href={t('usps.seeWhichOneIsClosestToYouLink')}
                className={classes.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('usps.seeWhichOneIsClosestToYou')}
              </a>
            </Typography>
          </Grid>
        </Grid>
        <a
          href={t('hero.aboutDaciaCareLink')}
          className={classes.blockLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('usps.learnMoreAboutDaciaCare')}
          <span className={classes.arrowIconWrapper}>
            <ArrowRight className={classes.icon} />
          </span>
        </a>
      </WidthLimiter>
    </div>
  )
}

export default withStyles(styles)(Usps)
